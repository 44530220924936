
.button.is-generic-app-blue.is-outlined:hover,
.button.is-generic-app-blue.is-outlined:focus {
  background: transparent;
  color: var(--color-primary-10);
}

.button.is-generic-app-blue.is-active,
.button.is-generic-app-blue:active {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.82);
  // background-color #2669b0
  // border-color #2669b0
  // color #fff
}

.are-attached {
  display: flex;

  button {
    transition: background-color 0.4s;
    opacity: 0.8;
    border-radius: 0;
    border-left: 0;
  }

  .field:first-child button {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 1px solid;
  }

  .field:last-child button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}


.inputs {
  display: flex;
  justify-content: center;

  .field {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.cov-vue-date,
.datepickbox input {
  display: block;
  width: 100% !important;
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

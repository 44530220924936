
.cc-pref-container-dialog {
  width: 100%;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.loading-wrapper {
  text-align: center;
  margin: 20px 0;
}

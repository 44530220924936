
.journal-export-report {
  display: flex;
  // flex-direction: column;
  gap: 15px;
  align-items: center;

  button {
    height: 2.8em;
  }
}

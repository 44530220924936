
#service-fee-table {
  width: auto;
  border-top: unset;

  tr {
    &.new-row {
      background: whitesmoke;
    }

    &:not(.parent):not(&.changed):not(&.inactive) {
      background: #ececec;
    }

    &.lastparent {
      td {
        padding-bottom: 10px;
      }
    }

    &.changed {
      background: #ffff2557;
    }

    &.inactive {
      background: #7796e057;
    }

    &.inactive.changed {
      background: #7266c057;
    }

    th {
      padding: 7px 2px 7px 5px;
      position: sticky;
      top: 0;
      z-index: 50;
      border-top: 1px solid #dbdbdb;

      &.header-category,
      &.value-header-type-day {
        font-weight: 400;
      }
    }

    td {
      padding: 2px 2px 2px 5px;
    }

    input[type="text"],
    input[type="number"] {
      height: 40px;
      background: transparent;
      box-shadow: none;
      padding: 0 5px;
      border: 1px solid rgba(192, 192, 192, 0.4);
    }

    .single-checkbox-select {
      label {
        display: flex;
        align-items: center;
        gap: 3px;
        padding: 0 4px;
        user-select: none;
      }
    }

    .value-wrap {
      display: flex;
      align-items: center;
      padding: 0 5px 0 0;

      input {
        min-width: 50px;
        background: white;
      }
    }
  }

  .fade-enter-active {
    transition: all 0.3s ease;
  }

  .fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .fade-enter,
  .fade-leave-to {
    transform: translateX(30px);
    opacity: 0;
  }
}

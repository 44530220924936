
.spacer-top {
  input {
    box-shadow: none;
    border: 1px solid #9bb4c1;
    height: 37px;
  }

  .control {
    i {
      top: 10px;
      position: absolute;
      right: 15px;
      font-size: 14px;
    }
  }
}

// About `map-merge()`:
// * only takes 2 arguments
// * is not recursive
// @param {Map} $map - first map
// @param {ArgList} $maps - other maps
// @param {Bool} $deep - recursive mode
// @return {Map}
@function map-extend($map, $maps...

  /*, $deep */
) {
  $last: nth($maps, -1);
  $deep: $last==true;
  $max: if($deep, length($maps) - 1, length($maps));

  // Loop through all maps in $maps...
  @for $i from 1 through $max {
    // Store current map
    $current: nth($maps, $i);

    // If not in deep mode, simply merge current map with map
    @if not $deep {
      $map: map-merge($map, $current);
    }

    @else {

      // If in deep mode, loop through all tuples in current map
      @each $key,
      $value in $current {

        // If value is a nested map and same key from map is a nested map as well
        @if type-of($value)=="map"and type-of(map-get($map, $key))=="map" {
          // Recursive extend
          $value: map-extend(map-get($map, $key), $value, true);
        }

        // Merge current tuple with map
        $map: map-merge($map, ($key: $value));
      }
    }
  }

  @return $map;
}

// Start customizing here!

@import 'utilities/_all.scss';
@import 'elements/_all.scss';

$tablet: 769px;
$desktop: 1140px;
$widescreen: 1192px;

$generic-app-blue: #2669b0;
$primary: $blue;
$generic-app-light-blue: #1D8BFF;


$link-hover: #222;

$title-color: #6591aa;

$generic-app-blue-i: findColorInvert($generic-app-blue);
$generic-app-light-blue-i: findColorInvert($generic-app-light-blue);

$generic-app-orange: #EBBC12;
$generic-app-orange-i: findColorInvert($generic-app-orange);

$generic-app-reject: #D20505;
;
$generic-app-reject-i: findColorInvert($generic-app-reject);
// custom colors map, after you define a color pair you can
// start using it similar to is-info, is-danger etc...
// where the part after is- will be how you name your pair here.

// you can see here few examples of how this might be set...
// add your own into this grop
$custom-colors: ('generic-app-blue': ($generic-app-blue, $generic-app-blue-i),
  'generic-app-orange': ($generic-app-orange, $generic-app-orange-i),
  'generic-app-reject': ($generic-app-reject, $generic-app-reject-i),
  'generic-app-light-blue': ($generic-app-light-blue, $generic-app-light-blue-i),
);

// IMPORTANT do not remove or modify this line
$colors: map-extend($colors, $custom-colors);

/* Our Zap Slideout styles...  */
*,
:after,
:before {
  box-sizing: border-box !important;
}
.menu-title img {
  max-width: 65px;
}
.menu-list li {
  text-align: center;
}
.menu-list li a {
  text-transform: uppercase;
  min-height: 85px !important;

  svg {
    width: 33px;
    height: 33px;

    path {
      fill: white;
    }

    &:hover {
      path {
        fill: var(--color-primary-90);
        transition: all 0.3s ease;
      }
    }
  }
  &.is-active {
    background-color: inherit;
    // color: var(--color-secondary-40);
    color: var(--color-primary-60);

    svg {
      path {
        fill: var(--color-primary-90);
      }
    }
  }
}
.menu-list li:nth-child(2) a {
  margin-top: 1.2em;
}

.main-content {
  margin-top: 5.5em;
  position: relative;
  background: white;
  transition: margin-left 0.3s;
  transition-delay: 0.04s;
  //transform: translateX(0);
  width: 100%;

  &.isOpen {
    margin-left: 130px;
  }

  > .container {
    .columns {
      // TODO Remove BULMA ASAP > Doesn't have flex for mobile view

      display: flex;
    }
  }

  &.modern-main-content {
    width: auto;
    flex: 1;
  }
}

.zap-slideout {
  position: fixed;
  width: 130px;
  height: 100vh;
  top: 0;
  z-index: 80;
  background-color: var(--color-secondary);
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transition: transform 0.3s;

  &.isOpen {
    transform: translateX(0);
    -ms-transform: translateX(0);
  }
}

.zap-slideout-opener {
  position: absolute;
  top: 20px;
  left: 100%;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #fff;
  cursor: pointer;
}

.zap-slideout-menu {
  font-weight: 600;
  color: #fff;
  overflow-y: auto;
  height: 100%;
  .fa {
    font-size: 33px;
  }
  li a {
    font-size: 12px;

    span {
      font-weight: 600;
    }
  }
  li a:hover {
    background-color: inherit;
    color: var(--color-primary-90);
  }
}

.zap-slideout-menu-item,
.zap-slideout-menu-item--small {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  & + & {
    margin-top: 20px;
  }
}

.zap-slideout-menu-item {
  font-size: 36px;

  & + .zap-slideout-menu-item--small {
    margin-top: 30px;
  }
}

.zap-slideout-menu-item--small {
  font-size: 18px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #ecf0f1;
}

/* The famed Zap agency logo (TM) */
.zap-emoji {
  height: 120px;
}

// .no-flexbox .zap-slideout-menu {
//   .fa {
//     display: block;
//     font-size: 33px;
//   }
// }

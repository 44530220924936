
.notification-email-from-list {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 20px 0;
  gap: 20px;

  $pending-color: #fff9dc;
  $modified-color: #d0e9ff;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;

    li {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 30px;
    }

    input {
      width: 100%;
    }

    .pending {
      input {
        background: $pending-color;
      }
    }

    .modified {
      input {
        background: $modified-color;
      }
    }
  }

  .legend {
    display: flex;
    align-items: center;
    gap: 15px;
    width: fit-content;
    border-radius: 5px;
    padding: 5px 10px;
    align-self: center;

    .color {
      display: flex;
      align-items: center;
      gap: 10px;

      &.pending {
        .palette {
          background: $pending-color;
        }
      }

      &.modified {
        .palette {
          background: $modified-color;
        }
      }

      .palette {
        width: 15px;
        height: 15px;
        border: 1px solid #cdcdcd;
      }
    }
  }
}


th {
  background: #fefefe;

  &.loc-allow,
  &.secure-allow,
  &.community-allow,
  &.oncall-shifts {
    text-align: center;
  }
}

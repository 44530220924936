
.time-warn {
  height: 180px;

  .body {
    padding: 2.5em;
    font-size: 1.2em;

    .info {
      text-align: center;
    }

    .options {
      margin-top: 1em;
      text-align: center;

      .button {
        margin: 5px;
        min-width: 8em;
      }
    }
  }
}

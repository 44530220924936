
#notification-centre-form.section {
  display: flex;
  flex-direction: column;
  gap: 3em;
  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1em 2em;
  }
  tbody {
    td,
    th {
      width: 5%;
      &.content {
        width: 80%;
        overflow: auto;
        p {
          width: 100%;
        }
      }

      .control2 {
        min-width: 100px;
      }
    }
  }
  .editor-wrap {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 100%;
    .input-bar {
      display: flex;
      gap: 1em;
    }

    .editor-signature {
      .signature {
        padding: 12px 15px;
        resize: none;
        border: 1px solid rgb(204, 204, 204);
        border-top: none;
        .remove-wrap {
          display: flex;
          align-items: center;
          gap: 0.5em;
          span {
            font-weight: 600;
            font-size: 0.9em;
            color: rgb(219, 48, 48);
            cursor: pointer;
          }
        }
      }
    }
    .attachments {
      display: flex;
      gap: 1em;
      .attachment {
        display: flex;
        gap: 1em;
      }
    }
    .signatures {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      span {
        font-size: 1.1em;
        font-weight: 600;
        color: var(--color-primary);
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.3em;
        img {
          width: 1em;
          height: 1em;
        }
      }
    }
    .buttons {
      display: flex;
      gap: 1em;
      label {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
          "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
          "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      }
    }
  }
}
.control {
  width: 270px;
  .error {
    display: inline-block;
    color: red;
    padding-top: 5px;
    padding-left: 8px;
  }
}
.notification-center-pagination {
  :deep(.pagination-list) {
    list-style: none !important;
    margin: 0;
  }
}

=arrow($color)
  border: 1px solid $color
  border-right: 0
  border-top: 0
  content: " "
  display: block
  height: 0.5em
  pointer-events: none
  position: absolute
  transform: rotate(-45deg)
  width: 0.5em

=block
  &:not(:last-child)
    margin-bottom: 1.5rem

=clearfix
  &:after
    clear: both
    content: " "
    display: table

=center($size)
  left: 50%
  margin-left: -($size / 2)
  margin-top: -($size / 2)
  position: absolute
  top: 50%

=delete
  // We need even pixel dimensions to ensure the delete cross can be perfectly centered
  $dimension-small: roundToEvenNumber(1.5 * removeUnit($size-6) * removeUnit($size-small)) * 1px
  $dimension-normal: roundToEvenNumber(1.5 * removeUnit($size-6) * removeUnit($size-normal)) * 1px
  $dimension-medium: roundToEvenNumber(1.5 * removeUnit($size-6) * removeUnit($size-medium)) * 1px
  $dimension-large: roundToEvenNumber(1.5 * removeUnit($size-6) * removeUnit($size-large)) * 1px
  +unselectable
  -moz-appearance: none
  -webkit-appearance: none
  background-color: rgba($black, 0.2)
  border: none
  border-radius: 290486px
  cursor: pointer
  display: inline-block
  font-size: $size-normal
  height: $dimension-normal
  outline: none
  position: relative
  transform: rotate(45deg)
  transform-origin: center center
  vertical-align: top
  width: $dimension-normal
  &:before,
  &:after
    background-color: $white
    content: ""
    display: block
    left: 50%
    position: absolute
    top: 50%
    transform: translateX(-50%) translateY(-50%)
  &:before
    height: 2px
    width: 50%
  &:after
    height: 50%
    width: 2px
  &:hover,
  &:focus
    background-color: rgba($black, 0.3)
  &:active
    background-color: rgba($black, 0.4)
  // Sizes
  &.is-small
    height: $dimension-small
    width: $dimension-small
  &.is-medium
    height: $dimension-medium
    width: $dimension-medium
  &.is-large
    height: $dimension-large
    width: $dimension-large

=fa($size, $dimensions)
  display: inline-block
  font-size: $size
  height: $dimensions
  line-height: $dimensions
  text-align: center
  vertical-align: top
  width: $dimensions

=hamburger($dimensions)
  cursor: pointer
  display: block
  height: $dimensions
  position: relative
  width: $dimensions
  span
    background-color: $text
    display: block
    height: 1px
    left: 50%
    margin-left: -7px
    position: absolute
    top: 50%
    transition: none $speed $easing
    transition-property: background, left, opacity, transform
    width: 15px
    &:nth-child(1)
      margin-top: -6px
    &:nth-child(2)
      margin-top: -1px
    &:nth-child(3)
      margin-top: 4px
  &:hover
    background-color: $background
  // Modifers
  &.is-active
    span
      background-color: $link
      &:nth-child(1)
        margin-left: -5px
        transform: rotate(45deg)
        transform-origin: left top
      &:nth-child(2)
        opacity: 0
      &:nth-child(3)
        margin-left: -5px
        transform: rotate(-45deg)
        transform-origin: left bottom

@keyframes spinAround
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)

=loader
  animation: spinAround 500ms infinite linear
  border: 2px solid $border
  border-radius: 290486px
  border-right-color: transparent
  border-top-color: transparent
  content: ""
  display: block
  height: 1rem
  position: relative
  width: 1rem

=overflow-touch
  -webkit-overflow-scrolling: touch

=overlay($offset: 0)
  bottom: $offset
  left: $offset
  position: absolute
  right: $offset
  top: $offset

=placeholder
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input'
  @each $placeholder in $placeholders
    &:#{$placeholder}-placeholder
      @content

=unselectable
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

// Responsiveness

$tablet: 769px !default
// 960px container + 40px
$desktop: 1000px !default
// 1152px container + 40
$widescreen: 1192px !default
// 960 and 1152 have been chosen because
// they are divisible by both 12 and 16

=from($device)
  @media screen and (min-width: $device)
    @content

=until($device)
  @media screen and (max-width: $device - 1px)
    @content

=mobile
  @media screen and (max-width: $tablet - 1px)
    @content

=tablet
  @media screen and (min-width: $tablet)
    @content

=tablet-only
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px)
    @content

=touch
  @media screen and (max-width: $desktop - 1px)
    @content

=desktop
  @media screen and (min-width: $desktop)
    @content

=desktop-only
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px)
    @content

=widescreen
  @media screen and (min-width: $widescreen)
    @content


.shift-schedule-invoice-errors {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px 0;
    padding: 0;
    overflow: auto;
    max-height: 70vh;

    li {
      // display: flex;
      min-height: 30px;
      height: 30px;
      align-items: center;
      padding: 0 10px;
      display: inline-flex;
      white-space: nowrap;
      min-width: 100%;

      &.item {
        &.error {
          background: #f5003169;
          color: black;
        }

        &.success {
          background: #00f51469;
          color: black;
        }
      }

      &.header,
      &.item {
        div {
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.id {
            min-width: 120px;
          }

          &.msg {
            width: 100%;
          }
        }
      }
    }
  }

  .center {
    text-align: center;
  }

  .buttons-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    button.button {
      margin: 17px 0.3em;
      min-width: 90px;
      height: 30px;
    }
  }
}

.icon
  +fa(21px, 1.5rem)
  .fa
    font-size: inherit
    line-height: inherit
  // Sizes
  &.is-small
    +fa(14px, 1rem)
  &.is-medium
    +fa(28px, 2rem)
  &.is-large
    +fa(42px, 3rem)

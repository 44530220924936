
$color--light-gray: #dde6eb;
$color--light-blue: #8399a6;
$color--blue: #2669b0;
$color--border: #9bb4c1;
$color--white: #ffffff;
$color--light-grey: #e3ebed;
$color--dark-blue: #405168;
$color--dark-grey: #7b7b7b;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;
.columns {
  padding: 10px 0 0 10px;
}

.hospital {
  min-width: 300px;
}

button {
  margin-right: 15px;
}

.help {
  // color: #00a1fd;
  // border-color: #00a1fd;
  margin-top: 0;
  font-size: 15px;
}

.input {
  width: 200px;
  height: 40px;
}

#search {
  position: absolute;
  top: 7px;
  left: 175px;
}

img {
  margin-right: 5px;
}

.disabled {
  opacity: 0.5;

  .divider {
    padding: 25px 0 45px 0;
    border-top: 2px solid $color--light-gray;
    min-height: 50px;

    .breaker {
      position: absolute;
      width: 1px;
      height: 100px;
      background-color: $color--light-gray;
      right: 0;
      top: 35px;
    }
  }
}

.report-filters {
  .main-search {
    position: relative;
    padding-right: 25px;
    margin-right: 15px;

    .control {
      padding-top: 25px;
    }
  }

  .input {
    box-shadow: none;
    border: 1px solid $color--border;
    height: 37px;

    &::placeholder {
      color: $color--light-blue;
      font-size: 15px;
    }
  }

  .control {
    i {
      top: 10px;
      position: absolute;
      right: 20px;
      font-size: 14px;
      color: $color--light-blue;
    }
  }
}

.shift-filters {
  display: flex;
  margin-bottom: 19px;
  flex-wrap: wrap;
}

.field {
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  label {
    margin: 0 1em;
  }
}

.field:first-child {
  margin-right: auto;
}

.label {
  color: #7c91ae;
}

.help {
  .is-danger {
    position: relative;
    left: 150px;
    margin-bottom: 20px;
  }
}

.error {
  width: 100%;
  text-align: right;

  p {
    margin-right: 150px;
    margin-bottom: 10px;
  }
}


#security-admin-deactivation-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  overflow: hidden;

  .center {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px 10px;
    align-items: center;
  }

  .loader {
    $size: 2rem;
    width: $size;
    height: $size;
    max-height: $size;
    max-width: $size;
  }
}


#security-section {
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem 0;

  .title {
    display: flex;

    .right-text {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .status-btn {
      margin-left: 20px;
    }
  }

  .multiselect {
    width: 300px;
    z-index: 2; // This is used because some tables have headers zi50
  }
}


#client-contacts-info {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  position: relative;
  height: 100%;

  .contact-type {
    min-width: 250px;
  }

  .spacer-top {
    display: flex;
    // justify-content: space-between; // Don't use
    align-items: center;
    gap: 10px;

    // input {
    //   box-shadow: none;
    //   border: 1px solid #9bb4c1;
    //   height: 37px;
    // }

    .field {
      position: relative;
      min-height: 40px;

      &.search {
        width: 25%;
      }

      i {
        top: 10px;
        position: absolute;
        right: 15px;
        font-size: 14px;
      }
    }
  }
}


$color--white: #ffffff;
$color--light-grey: #e3ebed;
$color--dark-blue: #405168;
$color--dark-grey: #7b7b7b;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;
$color--generic-app-unchecked: #3f5273;
$color--cpl: #30bb26;

.cc-pref-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .table-wrap {
    overflow: auto;
  }
}

table.generic-app-cc-pref {
  tr {
    th {
      text-align: center;
      color: #7c91ae;
      font-size: 13px;
      font-weight: 900;
      border-width: 0 0 1px;

      div.preferred {
        color: $color--cpl;
      }

      div.nonpreferred {
        color: $color--generic-app-unchecked;
      }
    }

    td {
      padding-left: 10px;
      border-bottom: 0px;
    }

    td.ccp-name {
      width: 250px;
    }

    td.pad-left {
      padding-left: 25px;
    }

    td.pad-left-2 {
      padding-left: 40px;
    }

    td.expandable {
      cursor: pointer;
      min-width: 70px;
      display: flex;
      align-items: center;
      margin: auto 0;
      padding: 10px;
    }

    td.fixed-height {
      height: 37px;
    }

    td.align-state {
      text-align: center;
    }
  }
}

.ctrl {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.ctrl__status {
  position: absolute;
  top: 5px;
  left: 0;
  height: 12px;
  width: 12px;
  background: $color--white;
  border: 1px solid $color--secondary;
  border-radius: 2px;

  .ctrl input:checked ~ & {
    background: $color--primary;
  }

  .ctrl input:disabled ~ & {
    background: $color--light-grey;
    opacity: 0.6;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;

    .ctrl input:checked ~ & {
      display: block;
    }

    .ctrl--checkbox & {
      left: 3px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid $color--white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .ctrl--checkbox input:disabled ~ & {
      border-color: $color--dark-grey;
    }
  }
}

.status--halfstate {
  background: linear-gradient(
    to right bottom,
    $color--white 50%,
    $color--primary 50%
  );
}

.location-group {
  &.disabled {
    opacity: 0.7;
  }
}

.circle {
  border-radius: 50%;
  background: $color--light-grey;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: $color--dark-blue;
  text-align: center;
  font-weight: 500;
  display: inline-block;
}

.fade-sh-enter-active,
.fade-sh-leave-active {
  transition: all 0.3s ease;
}

.fade-sh-enter,
.fade-sh-leave-to {
  opacity: 0;
}

.modal {
  label {
    margin-top: 0px;
  }
}

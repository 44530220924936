
.cov-datepicker {
  font-size: 18px;
  padding: 10px;
  border: 1px solid #9bb4c1 !important;
  border-radius: 5px !important;
}
.errorDate .cov-datepicker {
  border: 1px solid #e41616 !important;
}
.pagination-link.is-current {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary-d20) !important;
  border-width: 3px;
}

.submit-shift-form {
  display: flex;
  flex-direction: column;

  .lost-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .explanation {
      font-size: 1.1rem;
      font-weight: 700;
      line-height: 1.5;
    }
    .time-interval {
      display: flex;
      .explanation {
        font-size: 28px;
        margin: 20px 0 0 0;
        cursor: pointer;
      }
      .label-and-interval {
        p {
          font-weight: 700;
          line-height: 1.5;
          color: hsl(0deg, 0%, 21%);
        }
        .input {
          width: 96%;
          margin: 0 10px 10px 0;
        }
      }
    }
    @media screen and (min-width: 768px) {
      max-width: 800px;
    }
  }
}


.table td.no-padding {
  padding: 0;
}

tbody.active {
  td {
    border-bottom: 1px solid #dbdbdbdc;
  }
}

.tbody {
  z-index: 200;
}

.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.generic-app-tag.generic-app-tag--deadline {
  background-color: #d2ecf5;
}

.generic-app-tag.generic-app-tag--expiry {
  background-color: #ffcc6a;
}

tbody.generic-app-subtable {
  z-index: 500;
  background-color: red;

  tr {
    background-color: #f2f7fa;
  }
}

i {
  margin-right: 0;
}

.g-letter {
  opacity: 0.5;

  &:hover {
    opacity: 0.4;
  }

  img {
    width: 70%;
  }
}

.allowances {
  // max-width: 200px;
  min-width: 210px;
}

.has-button {
  &.rate,
  &.auto-repush {
    $icon-col: gray;
    text-align: center;

    i {
      color: $icon-col;
      cursor: pointer;

      &:hover {
        color: lighten($color: $icon-col, $amount: 20);
      }
    }
  }

  &.rate {
    a {
      display: flex;
      flex-direction: column;

      i {
        margin: 0;
      }

      * {
        pointer-events: none;
      }
    }
  }
}

/**
 * Default
 */
$color-default-default: #aaa;
$color-default-green: #53b96e;
$color-default-blue: #539bb9;
$color-default-red: #b95353;
$color-default-orange: #b97953;
$color-default-yellow: #bab353;

$theme-default-colors: (
  default: $color-default-default,
  blue: $color-default-blue,
  red: $color-default-red,
  yellow: $color-default-yellow,
  orange: $color-default-orange,
  green: $color-default-green,
);

/**
 * Bulma
 */
$color-bulma-default: #f5f5f5;
$color-bulma-primary: #00d1b2;
$color-bulma-blue: #3273dc;
$color-bulma-red: #ff3860;
$color-bulma-yellow: #ffdd57;
$color-bulma-green: #22c65b;

$theme-bulma-colors: (
  default: $color-bulma-default,
  primary: $color-bulma-primary,
  blue: $color-bulma-blue,
  red: $color-bulma-red,
  yellow: $color-bulma-yellow,
  green: $color-bulma-green,
);

.vue-switcher {
  position: relative;
  display: inline-block;

  &__label {
    display: block;
    font-size: 10px;
    margin-bottom: 5px;
  }

  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  div {
    height: 15px;
    width: 36px;
    position: relative;
    border-radius: 30px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: linear 0.2s, background-color linear 0.2s;

    &:after {
      content: "";
      height: 20px;
      width: 20px;
      border-radius: 100px;
      display: block;
      transition: linear 0.15s, background-color linear 0.15s;
      position: absolute;
      left: 100%;
      margin-left: -18px;
      cursor: pointer;
      top: -3px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &--unchecked {
    div {
      justify-content: flex-end;

      &:after {
        left: 15px;
      }
    }
  }

  &--disabled {
    div {
      opacity: 0.3;
    }

    input {
      cursor: not-allowed;
    }
  }

  &--bold {
    div {
      top: -8px;
      height: 26px;
      width: 51px;

      &:after {
        margin-left: -24px;
        top: 3px;
      }
    }

    &--unchecked {
      div {
        &:after {
          left: 28px;
        }
      }
    }

    .vue-switcher__label {
      span {
        padding-bottom: 7px;
        display: inline-block;
      }
    }
  }

  &-theme--default {

    @each $colorName,
    $color in $theme-default-colors {
      &.vue-switcher-color--#{""+$colorName} {
        div {
          // @if $colorName== "default" {
          //   background-color: lighten($color, 5%);
          // } @else {
          //   background-color: lighten($color, 10%);
          // }

          // &:after {
          //   @if $colorName== "default" {
          //     background-color: darken($color, 5%);
          //   } @else {
          //     background-color: $color;
          //   }
          // }
          background-color: var(--color-toggle-on);

          &:after {
            background-color: var(--color-toggle-on);
          }
        }

        &.vue-switcher--unchecked {
          div {
            // @if $colorName== "default" {
            //   background-color: $color;
            // } @else {
            //   background-color: lighten($color, 30%);
            // }

            // &:after {
            //   background-color: lighten($color, 10%);
            // }
            background-color: var(--color-toggle-off);

            &:after {
              background-color: var(--color-toggle-off);
            }
          }
        }
      }
    }
  }

  &-theme--bulma {

    @each $colorName,
    $color in $theme-bulma-colors {
      &.vue-switcher-color--#{""+$colorName} {
        div {
          // @if $colorName== "default" {
          //   background-color: darken($color, 10%);
          // } @else {
          //   background-color: lighten($color, 10%);
          // }

          // &:after {
          //   background-color: var($color);
          // }
          background-color: var(--color-toggle-on);

          &:after {
            background-color: var(--color-toggle-on);
          }
        }

        &.vue-switcher--unchecked {
          div {
            // @if $colorName== "default" or $colorName== "yellow" {
            //   background-color: darken($color, 5%);
            // } @else {
            //   background-color: lighten($color, 30%);
            // }

            // &:after {
            //   @if $colorName== "default" {
            //     background-color: $color;
            //   } @else {
            //     background-color: lighten($color, 10%);
            //   }
            // }
            background-color: var(--color-toggle-off);

            &:after {
              background-color: var(--color-toggle-off);
            }
          }
        }
      }
    }
  }
}

.loading-wrapper {
  padding: 20px;
}

.newInvoice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.all-clients {
  padding-top: 3em;
}

.noinvoice-preview {
  position: relative;

  .right {
    right: 0px;
  }
}

.single-invoice {
  width: 75%;
}

.center-action {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px 40px;
}


#manage-next-of-kin {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .rows {
      display: flex;
      flex-direction: column;
      gap: 10px;
      min-height: 300px;
      max-height: 400px;
      overflow: auto;
      padding: 0 10px 5px 0;
      // Bottom padding because of the scroll
      width: 100%;

      .row {
        display: flex;
        gap: 10px;

        input {
          height: 40px;

          &.error {
            background: #ffedf0;
            border: 1px solid crimson;
          }
        }

        .group-input {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .remove-wrap {
          width: 50px;
          align-self: flex-end;
          margin: 0 0 4px 10px;

          img {
            cursor: pointer;
          }
        }
      }
    }

    .loader-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 100%;
    }

    .button {
      align-self: flex-start;
    }
  }
}


.expense-details-wrap {
  display: flex;
  flex-direction: column;
  .rejection-box,
  .confirm-box {
    h2 {
      display: flex;
      justify-content: space-between;
    }
    textarea {
      width: 100%;
      height: 100px;
      resize: none;
    }
  }
  .button-line {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    button {
      margin: 0 10px;
    }
  }
  table {
    tr {
      &.initial-value {
        background-color: lightyellow;
      }
    }
  }
}


.slide-down-enter-active {
  transition: all 0.6s;
}

.slide-down-leave-active {
  transition: all 0.3s;
}

.slide-down-enter {
  transform: translateY(-50px);
}

.slide-down-leave-to {
  transform: translateY(-50px);
  z-index: 1000;
}

.slide-down-leave {
  height: 0;
}

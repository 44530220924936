
.custom-flatpickr {
  .flatpickr-calendar {
    width: 100%;

    .flatpickr-month {
      border-bottom: 1px solid #cdcdcd;
      height: 50px;
      padding-top: 8px;

      .cur-month {
        font-weight: 100;
      }
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      z-index: 1; // z-0 doesn't work for prev. month arrow
      top: 8px;
      padding: 10px calc(3.57% - 1.5px);
    }

    .flatpickr-rContainer {
      margin-left: auto;
      margin-right: auto;

      .flatpickr-weekdays {
        height: 60px;
        font-size: 20px;
        font-weight: 100;
      }

      .flatpickr-days {
        width: 100%;
        padding-top: 10px;

        .dayContainer {
          width: 100%;
          min-width: 100%;
        }
      }
    }

    &.arrowTop,
    &.arrowBottom {
      &::before,
      &::after {
        content: none;
      }
    }

    .flatpickr__clear-date {
      display: none;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      width: 21px;
      height: 21px;
      top: -9px;
      right: -9px;
      cursor: pointer;
    }

    .flatpickr-day {
      border-radius: 3px;
      margin-bottom: 5px;
      margin-right: 3px;
      font-size: 19px;
      max-width: 36px;
      height: 36px;
      line-height: 33px;

      &.selected {
        background-color: #74b5fd;

        &.with-time {
          background-color: #1173d6;

          &.is-for-change {
            background-color: #e3111b;
          }

          &:hover {
            .flatpickr__clear-date {
              display: block;
            }
          }
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .flatpickr-calendar {
      .flatpickr-day {
        color: rgba(57, 57, 57, 0.1);
        // background-color: rgba(57, 57, 57, 0.1);
      }
    }
  }

  // handling different screen sizes
  @media screen and (min-width: 560px) and (max-width: 960px) {
    .flatpickr-rContainer {
      width: 432px;

      .dayContainer {
        padding-right: 10px;
        padding-left: 10px;

        .flatpickr-day {
          margin-right: 18.24px;

          &:nth-child(7n + 0) {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1221px) {
    .flatpickr-rContainer {
      width: 600px;

      .dayContainer {
        padding-left: 24px;
        padding-right: 24px;

        .flatpickr-day {
          margin-right: 46px;

          &:nth-child(7n + 0) {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1222px) {
    .flatpickr-rContainer {
      width: 740px;

      .dayContainer {
        padding-left: 29px;
        padding-right: 29px;

        .flatpickr-day {
          margin-right: 58px;

          &:nth-child(7n + 0) {
            margin-right: 0;
          }
        }
      }
    }
  }
}


#rates-increments-form {
  .name {
    margin: 0 0 20px 0;
  }

  .cat-hidden {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .lbl {
      margin-right: 10px;
    }
  }

  .button {
    margin: 10px 0 0 0;

    &.cancel {
      margin-left: 10px;
    }
  }
}

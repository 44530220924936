
$color--white: #ffffff;
$color--light-grey: #e3ebed;
$color--dark-blue: #405168;
$color--dark-grey: #7b7b7b;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;

.edit-popup {
  width: 280px;
  max-height: 350px;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 3px;
  background-color: #ffffff;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  gap: 10px;

  .spacer {
    padding: 0 10px 10px;

    .button {
      height: 2em;
    }
  }

  .p-title {
    opacity: 0.4;
    color: #405168;
    text-align: center;
    padding: 10px;

    span {
      font-size: 11px;
      font-weight: bold;
      line-height: 13px;
    }
  }

  .circle {
    border-radius: 50%;
    background: $color--light-grey;
    width: 24px;
    height: 24px;
    line-height: 24px;
    color: $color--dark-blue;
    text-align: center;
    font-weight: 500;
  }

  > ul {
    padding-bottom: 10px;
  }

  label.disabled {
    color: #b6b6b6;
    cursor: default;
  }
}

div.row {
  display: flex;
  flex: 1 100%;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px 5px 10px;

  label {
    flex: 2;
    font-size: 14px;
    color: $color--dark-blue;
    font-weight: 500;
  }
}

.ctrl {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 18px;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.ctrl__status {
  position: absolute;
  top: 5px;
  left: 0;
  height: 12px;
  width: 12px;
  background: $color--white;
  border: 1px solid $color--secondary;
  border-radius: 2px;

  .ctrl input:checked ~ & {
    background: $color--primary;
  }

  .ctrl input:disabled ~ & {
    background: $color--light-grey;
    opacity: 0.6;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;

    .ctrl input:checked ~ & {
      display: block;
    }

    .ctrl--checkbox & {
      left: 3px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid $color--white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .ctrl--checkbox input:disabled ~ & {
      border-color: $color--dark-grey;
    }
  }
}

.ctrl--switch {
  display: inline-block;
  padding-left: 10px;
}

.status--halfstate {
  background: linear-gradient(
    to right bottom,
    $color--white 50%,
    $color--primary 50%
  );
}

.fade-enter-to,
.fade-leave {
  max-height: 500px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}

.target {
  transition: all 0.5s;
  overflow: hidden;

  li {
    margin: 0 10px;

    &:first-child {
      border-top: 1px solid $color--light-grey;
      padding-top: 5px;
    }
  }
}

li.li-label {
  display: flex;
  justify-content: space-between;
  color: #bbb;
  border-bottom: 1px solid #eee;
  padding-bottom: 3px;
  margin-bottom: 5px;
}

.loading-wrapper {
  text-align: center;
}

.button-wrapper {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.permission-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > .checkbox-list-menu {
    overflow: auto;
    height: 100%;
    padding: 0 2px 0 0;
    gap: 10px;

    &.root {
      &::v-deep {
        &.filtered {
          > .node.expanded {
            min-height: 100px;
          }
        }
        &:not(.filtered) {
          > .node.expanded {
            min-height: 200px;
          }
        }
        .node {
          max-height: 300px;
        }
      }
    }
  }
}

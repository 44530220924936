
$base-font-size: 16px;
$primary-font-color: #1b2432;

$outline-color: #d4d7db;
$background-color-count: #f6f8f9;

@function em($px, $base: $base-font-size) {
  @return calc($px / $base) * 1em;
}

.dash-card {
  display: flex;
  flex-direction: column;

  .card-link {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid $outline-color;
    overflow: hidden;
    height: 100%;

    .count {
      display: grid;
      place-items: center;
      background-color: $background-color-count;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: em(81px);
      height: 105px;
    }

    .bottom {
      display: flex;
      min-height: 65px;
      border-top: 1px solid $outline-color;
      height: 100%;

      .icon {
        min-width: 75px;
        margin: auto;
      }

      .label {
        width: 100%;
        border-left: 1px solid $outline-color;
        font-weight: 400;
        padding: 10px;
        word-break: break-word;
      }
    }
  }
}


.inputs {
  display: flex;

  &.is-awaken {
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0;

    input {
      cursor: pointer;
      margin-top: 6px;
      margin-right: 10px;
    }

    label {
      cursor: pointer;
    }
  }
}

.has-suffix {
  display: flex;
  align-items: center;
}

input + span.is-suffix {
  font-size: smaller;
  flex: 1 0 auto;
  margin-left: 1em;
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

.p-left10 {
  padding-left: 10px;
}

.p-right10 {
  padding-right: 10px;
}

.delete-section {
  margin: 10px 0;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(#405168, 0.15);
  border-bottom: 1px solid rgba(#405168, 0.15);
  align-items: center;
  justify-content: center;
  user-select: none;

  .action-message {
    display: flex;
    gap: 5px;
    margin-right: 1em;
    color: #405168;
    text-align: center;
    font-weight: 600;

    .user-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
    }
  }

  .action-buttons {
    a.button {
      border: none;
      margin: 0 0.3em;
    }
  }

  .is-confirm,
  .is-deny {
    font-weight: 900;
    font-size: 1.1em;
  }

  .is-confirm {
    color: #ff3b3b;
  }

  .is-deny {
    color: #2669b0;
  }
}

.fade-enter-to,
.fade-leave {
  max-height: 500px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}

.fade-enter-active {
  transition: all 20s ease;
}

.fade-leave-active {
  transition: all 0s ease;
}


.fade-enter-active {
  transition: all 0.3s ease;
}

.fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}

.fade-cubic-enter-active {
  transition: all 0.1s ease;
}

.fade-cubic-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-cubic-enter,
.fade-cubic-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.controls {
  .button {
    margin-left: 0.5em;
  }
}

td {
  vertical-align: middle;

  .flexer {
    display: flex;
    align-items: center;

    span:first-child {
      align-self: center;
      display: flex;
      flex: 0 0 15px;
      align-items: center;
      margin-right: 1em;
      cursor: pointer;
    }
  }
}

.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.generic-app-tag.generic-app-tag--deadline {
  background-color: #d2ecf5;
}

.generic-app-tag.generic-app-tag--expiry {
  background-color: #ffcc6a;
}

.new-document {
  img {
    margin-right: 15px;
  }
}

@import "./bulma-custom.scss"; // custom settings
@import "../bulma/bulma.sass";
@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "~vue2-animate/dist/vue2-animate.min.css";

@import "~flatpickr/dist/flatpickr.min.css";

@import "navigation.scss";

$color--white: #ffffff;
$color--light-grey: #e3ebed;
$color--dark-blue: #405168;
$color--dark-grey: #7b7b7b;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;
$sidebar-color: #34495e;

body {
  font-family: "Lato", sans-serif;
}

hr {
  margin: 16px 0;
}

.table td {
  padding: 0.66em 0.75em;
}

h1.title {
  [class*="icon-"] {
    font-size: 30px;
    color: var(--color-secondary-d80);
  }
}

/*
 * Global Fixes
 */
i[class*=" fa-"] {
  margin-right: 0.3em;
  position: relative;
  top: -2px;
}

/*
 * HELPERS
 */
.text-smaller {
  font-size: smaller;
}

.is-caps-lock {
  text-transform: uppercase;
}

.clickable {
  cursor: pointer;
}

/*
 * Form related
 */
.notification {
  border-radius: 0;
}

.help {
  font-size: 1.1em;
}

.button:not(.toggle-button) {
  height: 2.75em;
}

.button.is-low:not(.toggle-button) {
  height: 2em;
}

.tabs-details {
  position: relative;
}

/*
 * Make dropdown select uniform in look with the rest
 * of inputs in Bulma, as this is a differen component.
 */
form.book-shift,
form.client-management,
form.timesheet,
form.register-temp-modal {
  .multiselect__input {
    min-height: 22px;
  }
}

.multiselect__tags {
  // box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1); // To match inputs

  .multiselect__single {
    margin-bottom: 8px;
  }

  .multiselect__input,
  .multiselect__single {
    font-size: 1rem !important;
    padding: 0;
  }

  .multiselect__placeholder {
    color: #65625dc2 !important;
    min-height: 24px;
    margin-bottom: 0;
    padding: 1px 0 0 0;
    font-weight: bold;
  }

  .multiselect__spinner {
    top: 3px;
  }
}

// GLOBAL CHECKBOX STYLE
div.around {
  display: flex;
  flex: 1 100%;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px 5px 10px;
}

div.around label {
  flex: 2;
  font-size: 14px;
  color: $color--dark-blue;
  font-weight: 500;
}

.ctrl {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}

.ctrl input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.ctrl__status {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background: $color--white;
  border: 2px solid $color--secondary;
  border-radius: 2px;
}

.ctrl input:checked ~ .ctrl__status {
  background: $color--primary;
}

.ctrl input:disabled ~ .ctrl__status {
  background: $color--light-grey;
  opacity: 0.6;
  pointer-events: none;
}

.ctrl__status:after {
  content: "";
  position: absolute;
  display: none;
}

.ctrl input:checked ~ .ctrl__status:after {
  display: block;
}

.ctrl--checkbox .ctrl__status:after {
  left: 3px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid $color--white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.ctrl--checkbox input:disabled ~ .ctrl__status:after {
  border-color: $color--dark-grey;
}

/*
 *Global scroll bar style
 */
::-webkit-scrollbar {
  width: 0.8em;
  height: 0.9em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary-90);
  outline: 1px solid slategrey;
}

/*
 * LOST Grid Stuff
 */
.lost-center {
  // lost-center: 1140px;
  max-width: 1140px;
  margin: 0 auto;
}

.lost-shift {
  @media screen and (min-width: 768px) {
    lost-column: 2/3;
    lost-offset: 1/6;
  }
}

.no-flexbox .modal {
  display: block;

  &.is-active {
    display: block;
  }
}

.no-flexbox .simplert {
  &.simplert--shown {
    display: block;
  }
}

.no-flexbox .tabs {
  ul {
    height: 35px;

    li {
      a {
        display: block;
      }

      float: left;
      width: 33%;
    }
  }
}

// Vue switches
.vue-switcher div:after {
  top: -4px !important;
  height: 18px !important;
  width: 18px !important;
}

.vue-switcher div {
  height: 10px !important;
}

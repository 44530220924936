
.password-policy-wrapper {
  width: 100%;
  .validation-new-password {
    display: flex;
    justify-content: center;
    padding: 5px 0px 0px 0px;
    gap: 1%;
    color: #c2c2c2;
    .correct {
      color: #2cb117;
    }
    .inInputNotCorrect {
      color: rgb(228, 8, 8);
    }
  }
}

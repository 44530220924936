
#notitcation-centre-show-message {
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-height: 35vh;
  padding: 1em 0 2em 0;
  .content-wrap {
    display: flex;
    flex-direction: column;
    .textarea {
      height: 500px;
      overflow: auto;
    }
  }
  .button-line {
    display: flex;
    justify-content: center;
    gap: 2em;
  }
  h2 {
    text-align: center;
  }
}


.columns {
  padding-left: 10px;
}

tr {
  &.grouped {
    &:not(.first):not(.last) {
      td {
        border-top: none;
        border-bottom: none;
      }
    }

    &.first {
      td {
        border-bottom: none;
      }
    }

    &.last {
      td {
        border-top: none;
      }
    }
  }
}

td.is-narrow {
  width: 120px !important;
}

th.is-narrow {
  width: 120px !important;
}

$color--light-gray: #b7bbbd;
$color--dark-gray: #7c91ae;

th {
  span {
    margin-right: 5px;
    color: $color--dark-gray;
  }

  .fa {
    font-size: 15px;
    margin-top: 3px;
    color: $color--light-gray;
  }
}

td {
  .fa.fa-eye {
    font-size: 15px;
    position: relative;
    left: -45px;
  }

  button {
    span {
      position: relative;
      left: 15px;
    }
  }

  .fa.fa-file-text-o {
    font-size: 15px;
    position: relative;
    left: -61px;
  }
}


.total-hours {
  display: flex;
  gap: 5px;
}

.timesheet {
  float: none;
  .label-and-interval.explanation {
    margin: 0px 0 5px 0;
    .lanel-message {
      background-color: #ffffff;
      font-size: 1.1rem;
      font-weight: 700;
      line-height: 1.5;
    }
  }
}
.cov-datepicker {
  font-size: 18px;
  padding: 10px;
  border: 1px solid #e2e7ea !important;
  border-radius: 5px !important;
  box-shadow: unset !important;
}
.errorDate .cov-datepicker {
  border: 1px solid #e41616 !important;
}

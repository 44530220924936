
#security-journals-notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  h1 {
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    width: 80%;
  }

  .table-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    width: 80%;
    max-height: 60vh;

    table {
      border-top: none;
      position: relative;
      margin-bottom: 0;
      .remove-wrap {
        .fa {
          margin-left: 5px;
          margin-top: 4px;
          cursor: pointer;
        }
      }
      tbody {
        tr {
          border: 1px solid #dde6eb;
        }
      }
      td {
        min-height: 100%;
      }
    }

    thead {
      tr:first-child {
        th {
          width: 20%;
          height: 36.2px;
          top: 0px;
        }
      }
      tr {
        th {
          top: 36px;
          height: 36.2px; // So it goes behind other row
        }
        th:not(:first-child) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td:not(:first-child) {
          text-align: center;
        }
      }
    }
    tfoot,
    tfoot th,
    tfoot td {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      background: #fff;
      border-top: 1px solid #dde6eb;
      border-bottom: 1px solid #dde6eb;
    }

    th {
      border-top: 1px solid #dde6eb;
      border-bottom: unset;
      position: sticky;
      z-index: 1;
    }
  }
  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?juhulk");
  src: url("../fonts/icomoon.eot?juhulk#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?juhulk") format("truetype"),
    url("../fonts/icomoon.woff?juhulk") format("woff"),
    url("../fonts/icomoon.svg?juhulk#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Compliance-documents:before {
  content: "\e907";
}

.icon-Client-user-management:before {
  content: "\e900";
}

.icon-Reports:before {
  content: "\e901";
}

.icon-Dashboard:before {
  content: "\e902";
}

.icon-Calendar:before {
  content: "\e90c";
}

.icon-Billing:before {
  content: "\e903";
}

.icon-Arbitration:before {
  content: "\e904";
}

.icon-Compliance-management:before {
  content: "\e905";
}

.icon-Edit-profile:before {
  content: "\e906";
}

.icon-candidate-compliance:before {
  content: "\e908";
}

.icon-Shift-details:before {
  content: "\e909";
}

.icon-Shift-Schedule:before {
  content: "\e90a";
}

.icon-rate-manage,
.icon-Security,
.icon-file-cloud-upload,
.icon-envelope {
  font-family: FontAwesome !important;
}

.icon-rate-manage:before {
  content: "\f1ec";
  /* content: "\f0ce"; */
}

.icon-Submit-shift:before {
  content: "\e90b";
}

.icon-Location-client-management:before {
  content: "\e90d";
}

.icon-Security:before {
  content: "\f132";
}

.icon-file-cloud-upload:before {
  content: "\f0ee";
}

.icon-envelope:before {
  content: "\f0e0";
}

.icon-All-Candidates:before {
  content: "\e90e";
}

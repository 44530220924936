
$color--light-gray: #dde6eb;
$color--light-blue: #8399a6;
$color--blue: #2669b0;
$color--border: #9bb4c1;
$color--white: #ffffff;
$color--light-grey: #e3ebed;
$color--dark-blue: #405168;
$color--dark-grey: #7b7b7b;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;

.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;

  &.disabled {
    opacity: 0.7;
  }
}

.divider {
  margin-top: 50px;
  padding: 10px 0 20px 0;
  border-top: 2px solid $color--light-gray;
  border-bottom: 2px solid $color--light-gray;
  min-height: 150px;

  .breaker {
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: $color--light-gray;
    right: 0;
    top: 35px;
  }
}

.candidate-filters {
  .searcher {
    position: relative;
    padding-right: 25px;
    margin-right: 15px;

    .control {
      padding-top: 25px;
    }
  }

  input {
    box-shadow: none;
    border: 1px solid $color--border;
    height: 37px;

    &::placeholder {
      color: $color--light-blue;
      font-size: 15px;
    }
  }

  .control {
    i {
      top: 37px;
      position: absolute;
      right: 15px;
      font-size: 14px;
      color: $color--light-blue;
    }
  }
}

.table {
  margin-top: 20px;

  thead {
    tr {
      height: 45px;
    }
  }

  th {
    text-align: left;
    color: #7c91ae;
    font-size: 13px;
    font-weight: 900;
    border-width: 0 0 1px;
  }

  td {
    color: #405168;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    &.locations {
      height: 50px;

      .loading-wrapper {
        height: 20px;
        width: 35px;
        padding: 0 0 0 10px;
      }
    }
  }
}

.ctrl {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  color: $color--blue;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.ctrl__status {
  position: absolute;
  top: -5px;
  height: 12px;
  width: 12px;
  background: $color--white;
  border: 1px solid $color--secondary;
  border-radius: 2px;

  .ctrl input:checked ~ & {
    background: $color--primary;
  }

  .ctrl input:disabled ~ & {
    background: $color--light-grey;
    opacity: 0.6;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;

    .ctrl input:checked ~ & {
      display: block;
    }

    .ctrl--checkbox & {
      left: 3px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid $color--white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .ctrl--checkbox input:disabled ~ & {
      border-color: $color--dark-grey;
    }
  }
}

#register {
  margin: 1em 0;
}

td.has-button {
  &.calendar {
    $icon-col: rgb(100, 100, 100);
    text-align: center;
    i {
      color: $icon-col;
      font-size: 18px;

      &:hover {
        color: lighten($color: $icon-col, $amount: 20);
      }
    }
  }

  .button {
    min-width: 80px;
  }
}

@media (max-width: 768px) {
  .breaker {
    display: none;
  }
}

:root {
  /* Default colors */
  /* This colors will be changed with API call */

  /* TEST */
  --color-primary-h: 120;
  --color-primary-s: 100%;
  --color-primary-l: 25%;
  --color-primary-hs: var(--color-primary-h), var(--color-primary-s);
  --color-primary-hsl: var(--color-primary-h), var(--color-primary-s),
    var(--color-primary-l);
  --color-primary: hsl(var(--color-primary-hsl));

  --color-secondary-h: 0;
  --color-secondary-s: 50%;
  --color-secondary-l: 65%;
  --color-secondary-hs: var(--color-secondary-h), var(--color-secondary-s);
  --color-secondary-hsl: var(--color-secondary-h), var(--color-secondary-s),
    var(--color-secondary-l);
  --color-secondary: hsl(var(--color-secondary-hsl));
  /* --color-primary: #2669b0; */
  /* --color-secondary: hsl(211, 100%, 65%); */

  /* Toggle */
  --color-toggle-on-hs: var(--color-toggle-on-h), var(--color-toggle-on-s);
  --color-toggle-off-hs: var(--color-toggle-off-h), var(--color-toggle-off-s);
  --color-toggle-on: hsla(var(--color-toggle-on-hs),
        calc(var(--color-toggle-on-l) + 3%),
        1);
  --color-toggle-off: hsla(var(--color-toggle-off-hs),
          calc(var(--color-toggle-off-l) + 3%),
          1);
/* End toggle */

  /* ================================================= Primary shades */
  /* Light */

 

  --color-primary-10: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) + 3%),
    1
  );
  --color-primary-20: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) + 6%),
    1
  );
  --color-primary-30: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) + 9%),
    1
  );
  --color-primary-40: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) + 12%),
    1
  );
  --color-primary-50: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) + 15%),
    1
  );
  --color-primary-60: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) + 18%),
    1
  );
  --color-primary-70: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) + 21%),
    1
  );
  --color-primary-80: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) + 24%),
    1
  );
  --color-primary-90: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) + 27%),
    1
  );

  /* Dark */
  --color-primary-d10: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) - 3%),
    1
  );
  --color-primary-d20: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) - 6%),
    1
  );
  --color-primary-d30: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) - 9%),
    1
  );
  --color-primary-d40: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) - 12%),
    1
  );
  --color-primary-d50: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) - 15%),
    1
  );
  --color-primary-d60: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) - 18%),
    1
  );
  --color-primary-d70: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) - 21%),
    1
  );
  --color-primary-d80: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) - 24%),
    1
  );
  --color-primary-d90: hsla(
    var(--color-primary-hs),
    calc(var(--color-primary-l) - 27%),
    1
  );

  /* ================================================= Secondary shades */
  /* Light */
  --color-secondary-10: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) + 3%),
    1
  );
  --color-secondary-20: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) + 6%),
    1
  );
  --color-secondary-30: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) + 9%),
    1
  );
  --color-secondary-40: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) + 12%),
    1
  );
  --color-secondary-50: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) + 15%),
    1
  );
  --color-secondary-60: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) + 18%),
    1
  );
  --color-secondary-70: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) + 21%),
    1
  );
  --color-secondary-80: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) + 24%),
    1
  );
  --color-secondary-90: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) + 27%),
    1
  );

  /* Dark */
  --color-secondary-d10: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) - 3%),
    1
  );
  --color-secondary-d20: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) - 6%),
    1
  );
  --color-secondary-d30: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) - 9%),
    1
  );
  --color-secondary-d40: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) - 12%),
    1
  );
  --color-secondary-d50: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) - 15%),
    1
  );
  --color-secondary-d60: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) - 18%),
    1
  );
  --color-secondary-d70: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) - 21%),
    1
  );
  --color-secondary-d80: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) - 24%),
    1
  );
  --color-secondary-d90: hsla(
    var(--color-secondary-hs),
    calc(var(--color-secondary-l) - 27%),
    1
  );
}


#rates-increments-table {
  $gray-element: rgba(10, 10, 10, 0.2);

  ul {
    // max-height: calc(100vh - 400px);
    overflow: auto;

    &#increments {
      overflow-y: scroll;
      height: 100%;
      margin-top: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: unset;
    }
  }

  .inc-row {
    min-width: 300px;

    &.create {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // input {
      // width: 300px;
      // }

      .add {
        // color: gray;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 17px;

        .fa {
          color: darken($color: $gray-element, $amount: 10);
        }

        &:not(.disabled) {
          cursor: pointer;

          &:hover {
            .fa {
              color: lighten($color: royalblue, $amount: 10);
            }
          }
        }

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
}


$calendar-neutral: #ebeff1;
$calendar-font-color: #2d5764;

$calendar-pale: #f2f7fa;
$calendar-blue: #0077e5;
$calendar-red: #e41616;

$calendar-light-green: #16c03d;
$calendar-dark-green: #14b338;

$calendar-light-grey: #c4cfd4;
$calendar-dark-grey: #b5c1c7;

.availability-content {
  height: 130px;
  display: flex;
  padding-top: 17px;

  .calendar__cell {
    width: 100% !important;
  }
}

.switches {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;

  .group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      text-align: right;
      width: 40px;
      margin-right: 10px;
    }
  }

  > label {
    flex: 1;
  }
}

.calendar__cell {
  background-color: $calendar-neutral;
  height: 110px;
  margin-bottom: 5px;
  display: flex;
  width: 75%;

  &.is-selected-cell {
    outline: 5px solid #294959;
  }

  &.has-no-gap {
    height: 40px;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    color: #2d5764;
  }

  .visual,
  .data {
    flex: 1;
    font-size: smaller;
  }

  .visual {
    display: flex;
    flex-direction: column;
  }

  .visual__day,
  .visual__night {
    background-color: $calendar-light-grey;
    height: 55px;
  }

  .visual__day,
  .visual__night {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    opacity: 0.6; // TODO just testing
    transition: background-color 1s;
  }

  .visual__night {
    background-color: $calendar-dark-grey;
  }

  .visual__day--available {
    background-color: $calendar-light-green;
  }

  .visual__night--available {
    background-color: darken($calendar-light-green, 11%);
  }

  .visual__day--booked {
    background-color: $calendar-blue;
  }

  .visual__night--booked {
    background-color: darken($calendar-blue, 11%);
  }

  .visual__day--action_needed {
    background-color: $calendar-red;
  }

  .visual__night--action_needed {
    background-color: darken($calendar-red, 11%);
  }

  .visual__day--all,
  .visual__night--all {
    color: darken(#4b5e6f, 20%);
  }

  .data {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #4b5e6f;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;

    svg {
      margin-right: auto;
    }
    svg:first-child {
      margin-left: 6px;
      width: 11%;
      height: auto;
    }
    svg:last-child {
      margin-left: 10px;
      width: 9%;
      height: auto;
    }
  }
}

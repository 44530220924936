
#rates-increments {
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: center;

    .category-select {
      width: auto;
      min-width: 300px;
    }
  }

  .bottom {
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;

    .loader {
      width: 30px;
      height: 30px;
      max-height: 30px;
      max-width: 30px;
    }

    > *:not(.loader) {
      flex: 0 0 40%;
      min-width: 350px;
      height: 100%;

      // max-height: calc(100vh - 400px); // Fixed
    }

    > *:first-child {
      margin-right: 20px;
    }

    > *:last-child {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .top,
    .bottom {
      overflow: auto;
      justify-content: flex-start;
    }
  }

  .slide-right-enter-active {
    transition: all 0.3s ease;
  }

  .slide-right-leave-active {
    transition: all 0.4s ease;
  }

  .slide-right-enter,
  .slide-right-leave-to {
    transform: translateX(-20px);
    opacity: 0;
  }
}


#client-invoice-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: hidden;

  .pending-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    // max-height: 640px;
    padding: 10px 0;
    overflow: auto;

    // &.auto {
    //   overflow: auto;
    // }
  }

  .row {
    display: flex;
    // align-items: center;
    gap: 10px;

    .control-row {
      display: flex;
      flex-direction: column;
    }

    .input {
      height: 40px;
    }

    .fa {
      // Don't center vertically
      // margin-top: 25px;
      cursor: pointer;

      &:hover {
        color: crimson;
      }
    }
  }

  .separator {
    width: 100%;
    border-top: 1px solid silver;
  }
}

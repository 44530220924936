
#holiday-request {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 20px 0;
  gap: 20px;
  overflow: hidden;

  .title {
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    gap: 20px 40px;
    justify-content: center;
    overflow: hidden;
  }
}

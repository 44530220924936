$pagination: $grey-darker !default
$pagination-background: $white !default
$pagination-border: $grey-lighter !default

$pagination-hover: $link-hover !default
$pagination-hover-border: $link-hover-border !default

$pagination-focus: $link-focus !default
$pagination-focus-border: $link-focus-border !default

$pagination-active: $link-active !default
$pagination-active-border: $link-active-border !default

$pagination-disabled: $grey !default
$pagination-disabled-background: $grey-lighter !default
$pagination-disabled-border: $grey-lighter !default

$pagination-current: $link-invert !default
$pagination-current-background: $link !default
$pagination-current-border: $link !default

$pagination-ellipsis: $grey-light !default

$pagination-shadow-inset: inset 0 1px 2px rgba($black, 0.2)

.pagination,
.pagination-list
  align-items: center
  display: flex
  justify-content: center
  text-align: center

.pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis
  +control
  +unselectable
  font-size: 0.875rem
  padding-left: 0.5em
  padding-right: 0.5em
  justify-content: center
  text-align: center

.pagination-previous,
.pagination-next,
.pagination-link
  border: 1px solid $pagination-border
  min-width: 2.5em
  &:hover
    border-color: $pagination-hover-border
    color: $pagination-hover
  &:focus
    border-color: $pagination-focus-border
  &:active
    box-shadow: $pagination-shadow-inset
  &[disabled],
  &.is-disabled
    background: $pagination-disabled-background
    color: $pagination-disabled
    opacity: 0.5
    pointer-events: none

.pagination-previous,
.pagination-next
  padding-left: 0.75em
  padding-right: 0.75em

.pagination-link
  &.is-current
    background-color: $pagination-current-background
    border-color: $pagination-current-border
    color: $pagination-current

.pagination-ellipsis
  color: $pagination-ellipsis
  pointer-events: none

.pagination-list
  li
    &:not(:first-child)
      margin-left: 0.375rem

+mobile
  .pagination
    flex-wrap: wrap
  .pagination-previous,
  .pagination-next
    flex-grow: 1
    flex-shrink: 1
    width: calc(50% - 0.375rem)
  .pagination-next
    margin-left: 0.75rem
  .pagination-list
    margin-top: 0.75rem
    li
      flex-grow: 1
      flex-shrink: 1

+tablet
  .pagination-list
    flex-grow: 1
    flex-shrink: 1
    justify-content: flex-start
    order: 1
  .pagination-previous,
  .pagination-next
    margin-left: 0.75rem
  .pagination-previous
    order: 2
  .pagination-next
    order: 3
  .pagination
    justify-content: space-between
    &.is-centered
      .pagination-previous
        margin-left: 0
        order: 1
      .pagination-list
        justify-content: center
        order: 2
      .pagination-next
        order: 3
    &.is-right
      .pagination-previous
        margin-left: 0
        order: 1
      .pagination-next
        order: 2
        margin-right: 0.75rem
      .pagination-list
        justify-content: flex-end
        order: 3


.table {
  .is-wide-tag {
    min-width: 50px;
    display: inline-flex;
    justify-content: space-between;
  }

  .generic-app-tag {
    position: relative;
    background-color: #e3ebed;
    border-radius: 3px;
    padding: 0.2em 0.5em;
  }
}


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 1.5em;
  .msg {
    padding: 15px;
    text-align: center;
  }
  .button-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
    button {
      font-size: 0.6em;
    }
  }
}


// .increment-tag {
//   height: 22px;
//   width: 63px;
//   opacity: 0.76;
//   border-radius: 3px;
//   font-weight: bold;
//   margin: auto;

//   &.pending {
//     background-color: #d19f1c;
//   }

//   &.active {
//     background-color: #2cb0ff;
//   }

//   &.history {
//     background-color: #b6c6cd;
//   }

//   span {
//     &.tagname:hover + .cancel {
//       // Display the cancel button on whole tag mouseover
//       display: block;
//     }

//     &.tagname {
//       text-transform: uppercase;
//       width: 100%;
//       color: #ffffff;
//       font-family: Lato;
//       font-size: 11px;
//       font-weight: 900;
//       line-height: 13px;
//       display: inline-block;
//       text-align: center;
//     }
//   }

//   .wrapper {
//     position: relative;

//     &:hover .cancel {
//       // Display the cancel button on wrapper mouseover
//       display: block;
//     }

//     .cancel {
//       position: absolute;
//       top: -6px;
//       right: -6px;
//       border-radius: 20px;
//       background-color: red;
//       width: 12px;
//       height: 12px;
//       font-size: 9px;
//       color: white;
//       display: none;

//       &:hover {
//         background-color: gray;
//         cursor: pointer;
//         display: block;
//       }
//     }
//   }
// }

.text-error {
  color: crimson;
}

.sub-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

td.editincremets-center {
  text-align: center;
}

th.center {
  text-align: center;
}

.modal-content {
  width: 80%;
  margin: 0 auto;
  padding: 0px;
}


.data-handler {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .file-list-management {
    max-height: 400px;
    overflow: auto;
  }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    min-height: 30px;
    height: 30px;
  }
}

.spinner-graphics {
  text-align: center;
  background: #f0f6f6;
  width: 50px;
}

.fade-enter-to,
.fade-leave {
  max-height: 500px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}

.check-temp-doc {
  width: 100%;
}

.caption {
  padding: 1em;
  display: flex;
  justify-content: center;
}

.is-confirm,
.is-deny {
  font-weight: 900;
  font-size: 1.1em;
}

.is-confirm {
  color: #ff3b3b;
}

.is-deny {
  color: #2669b0;
}

div.delete-section {
  display: flex;
  border-top: 1px solid rgba(#405168, 0.15);
  border-bottom: 1px solid rgba(#405168, 0.15);
  align-items: center;
  justify-content: center;

  .action-message {
    margin-right: 1em;
    color: #405168;
  }

  .action-buttons {
    a.button {
      border: none;
      margin: 0 0.3em;
    }
  }
}

footer {
  padding: 1.5em 0;
  display: flex;
  justify-content: center;
}

table {
  width: 100%;
  border: 0;
  margin-bottom: 0;
  padding: 1em 0;
  border-radius: 0;

  td {
    a {
      color: #2669b0;
    }
  }

  td.deleting {
    a {
      color: red;
    }
  }

  thead td {
    color: #7c91ae;
    border: none;
    font-weight: 900;
    font-size: smaller;
  }
}

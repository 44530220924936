
.rates-change-value-modal {
  min-height: 600px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .inner-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    overflow: hidden;

    .info-label {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .action {
      display: flex;
      gap: 10px;
      overflow: hidden;

      .checkbox-complete {
        overflow: auto;
        padding-right: 10px;
      }

      .cats {
        display: flex;
        flex-direction: column;
        gap: 5px;
        overflow: hidden;
        flex: 1;
      }

      .input-section {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }
  }
}

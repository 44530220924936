
.journal-filters {
  display: flex;
  // flex-wrap: wrap;
  gap: 20px;

  .control {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    input {
      height: 100%;
    }
  }

  .left-side,
  .right-side {
    display: flex;
    gap: 20px;
    align-items: flex-end;
  }

  .date-filter {
    display: flex;
    gap: 10px;
    align-items: flex-end;

    .button {
      align-self: flex-end;
    }
  }

  .note {
    input {
      height: 37px;
    }
  }

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }
}

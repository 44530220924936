
.rectangle {
  input {
    color: #2669b0;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    height: 40px;
    width: 40px;
    border: 1px solid #2669b0;
    border-radius: 3px;
    background-color: #ffffff;

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

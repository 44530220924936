
.pagination {
  $color: #405168;
  display: inline-flex;
  float: right;

  a {
    color: $color;
  }

  .pagination-list {
    justify-content: flex-end;

    li {
      margin: 0 !important;
    }
  }

  .pagination-previous {
    margin: 0;
    border-radius: 4px 0px 0px 4px;
  }

  .pagination-next {
    margin: 0;
    border-radius: 0px 4px 4px 0px;
  }

  .pagination-link {
    border-radius: 0;
  }
}


.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.delete-btn {
  height: 20px;
  width: 64px;
  border-radius: 3px;
  background-color: #d20505;
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: baseline-top;

  &:hover {
    background-color: #f42727;
    cursor: pointer;
  }

  .content {
    height: 12px;
    width: 37px;
    color: #ffffff;
    font-family: Lato;
    font-size: 10px;
    font-weight: bold;
    line-height: 20px;
    display: block;
    margin: 0 auto;
  }
}

.pin {
  height: 18px;
  width: 35px;
  color: #405168;
  font-family: Lato;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

img.pin-edit {
  margin-left: 2px;
  clear: right;
  display: inline-block;
  cursor: pointer;
}

td {
  &.username {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  &.subcats {
    height: 50px;

    .loading-wrapper {
      height: 20px;
      width: 35px;
      padding: 0 0 0 10px;
    }
  }

  &.locations {
    height: 50px;

    .loading-wrapper {
      height: 20px;
      width: 35px;
      padding: 0 0 0 10px;
    }
  }
}

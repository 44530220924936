
.round-quote-calc {
  display: flex;

  .table-wrap {
    width: 100%;
    font-size: 0.9rem;

    table {
      th {
        padding: 5px;
      }

      td {
        text-align: center;
      }
    }
  }

  .no-data {
    text-align: center;
    color: crimson;
    font-weight: bold;
  }

  .loading-wrapper {
    text-align: center;
  }
}


#rates-increments-node {
  $gray-element: rgba(10, 10, 10, 0.2);
  $red-element: rgba(238, 96, 124, 0.733);

  .node-click {
    display: flex;
    border-bottom: 1px solid rgba(192, 192, 192, 0.363);
    padding: 5px 10px;
    // border-left: unset;
    // border-right: unset;
  }

  &.active {
    background: darken($color: #e0e0e0ce, $amount: 10);
  }

  &.hidden {
    background: rgba(219, 219, 219, 0.335);
    opacity: 0.4;
    color: rgb(39, 39, 39);
  }

  &:hover {
    background: darken($color: #e0e0e0ce, $amount: 5);
  }

  .action {
    margin-left: auto;
  }

  .delete {
    background: $gray-element;

    &:hover {
      background: $red-element;
    }
  }
}


.inputs {
  display: flex;
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

.p-left10 {
  padding-left: 10px;
}

.p-right10 {
  padding-right: 10px;
}


span.sep {
  border: 1px solid #d6e0e5;
  margin: 0 0.8em;
}

.flexcell {
  display: flex;
  align-items: center;
}

.dot {
  border-radius: 7px;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 0.8em;
}

.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

td.button-group {
  .button {
    margin: 0 1em 3px 0;
  }
}

.is-loading {
  text-align: center;
  background: #f2f7fa;
}


.expense-details-wrap {
  display: flex;
  flex-direction: column;
  .button-line {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
  table {
    .initial-value {
      background-color: lightyellow;
    }
  }
}


.candidate-page {
  display: flex;
  flex-direction: column;

  span.sep {
    border: 1px solid #d6e0e5;
    margin: 0 0.8em;
  }

  .candidate-inner-view {
    padding: 20px 0 0 0;
    display: flex;
    // overflow: hidden;
  }
}


.is-composite {
  display: flex;

  .sidebar-info {
    display: flex;
    flex-direction: column;
    display: flex;
    background-color: #eef5f5;
    border: 1px solid #cdd5dd;
    width: 15em;

    .upper {
      border-top-left-radius: 8px;
      flex: 1;
    }

    .lower {
      border-bottom-left-radius: 8px;
      flex: 1;
    }
  }

  .comment-box {
    flex: 1;
  }

  .progress {
    background: #dbdbdbdc;
    height: 0.38em;
    border-radius: 0;
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;

    .line {
      height: 100%;

      &.high {
        background: var(--color-primary-10);
      }

      &.med {
        background: #bdbd5b;
      }

      &.low {
        background: red;
      }
    }
  }
}
textarea {
  border-radius: 0;
  // border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.fa.fa-check {
  margin-right: 0.5em;
}

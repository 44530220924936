
#manage-rates {
  padding: 3rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;

  > .inner-page {
    margin-top: 20px;
    overflow: hidden;
    height: 100%;
  }
}

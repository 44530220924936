
#client-profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  overflow: hidden;

  .form {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
    overflow: auto;
    height: 100%;

    .left-side,
    .right-side {
      width: 100%;
      max-width: 350px;
    }
  }

  input {
    height: 40px;
  }
}


#candidate-holiday-request {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .table-wrap {
    display: flex;
    flex-direction: column;
  }

  .pagination {
    align-self: flex-end;
  }
}

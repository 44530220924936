
$color--light-gray: #b7bbbd;
$color--dark-gray: #7c91ae;

// .is-disabled {
//   disabled: true;
// }

th {
  span {
    margin-right: 5px;
    color: $color--dark-gray;
  }

  .fa {
    font-size: 15px;
    margin-top: 3px;
    color: $color--light-gray;
  }
}

.fa-long-arrow-up {
  &.active {
    color: var(--color-primary);
  }
}

td {
  .fa.fa-eye {
    font-size: 15px;
    // position: relative;
    // left: -45px;
  }

  // button {
  //   span {
  //     position: relative;
  //     left: 15px;
  //   }
  // }

  .fa.fa-file-text-o {
    font-size: 15px;
    // position: relative;
    // left: -61px;
  }
}

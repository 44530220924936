
.dashboard-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  .spinner {
    display: flex;
    justify-content: center;
    height: 100px;
    margin: 50px auto;

    img {
      width: 100px;
    }
  }

  .container {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 30px 50px;

    // .dash-card {
    //   grid-area: 1 / 1 / 4 / 4;
    // }
  }
}


.progress-bar-wrapper {
  width: 100%;
  margin: 25px auto;
  text-align: center;
}

.progress-bar {
  width: 80%;
  margin: auto;
  height: 15px;
  border-radius: 5px;
  box-shadow: inset 0 -1px 2px 0 rgba(200, 200, 200, 0.5),
    inset 0 1px 3px 0 rgba(122, 122, 122, 0.5);
  position: relative;
}

.current-progress {
  height: 100%;
  background: #2669b0;
  width: 0%;
  transition: all 0.2s ease;
  box-shadow: inset 0 -1px 2px 0 rgba(200, 200, 200, 0.5),
    inset 0 1px 3px 0 rgba(122, 122, 122, 0.5);
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
}


.user-settings-form {
  width: 100%;
  .hideMessagePasswordConfirmation {
    color: red;
    display: none;
  }
  .showMessagePasswordConfirmation {
    color: red;
    display: block;
    text-align: center;
  }
  // .control {
  //   .validation-new-password {
  //     color: #c2c2c2;
  //     display: flex;
  //     .correct {
  //       color: #2cb117;
  //     }
  //   }
  // }
  // .validation-password {
  //   color: red;
  // }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

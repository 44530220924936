
.client-copy-cost-center {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;

  .header-title {
    text-align: center;
  }

  .input-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .button-wrap {
    display: flex;
    gap: 15px;
    justify-content: center;
  }
}

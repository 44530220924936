
.uni-popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  gap: 10px;
  padding: 10px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    opacity: 0.4;
    color: #405168;
    text-align: center;
    padding: 3px 10px;
    text-transform: uppercase;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}


$color--grey: #7c91ae;
$color--light-blue: #8399a6;
$color--dark-blue: #2669b0;
$color--darker-blue: #405168;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;
$color--white: #ffffff;
$color--border: #9bb4c1;
$color--light-grey: #e3ebed;
$color--dark-grey: #7b7b7b;

.search-filter {
  position: relative;

  .name {
    color: $color--grey;
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
    line-height: 15px;
    padding: 5px 0;
    height: 25px;
  }

  .search-container {
    max-width: 80%;
    position: relative;

    i {
      top: 34px;
      position: absolute;
      right: 15px;
      font-size: 14px;
      color: $color--dark-blue;
    }
  }

  .circle {
    position: absolute;
    left: 85%;
    top: 32px;
    border-radius: 50%;
    background: $color--grey;
    width: 24px;
    height: 24px;
    color: $color--white;
    text-align: center;
    font-weight: 500;
    line-height: 24px;
  }

  input {
    box-shadow: none;
    border: 1px solid $color--border;
    height: 37px;

    &::placeholder {
      color: $color--light-blue;
      font-size: 15px;
    }
  }

  i.fa-search {
    top: 22px;
    position: absolute;
    right: 20px;
    font-size: 14px;
    color: $color--light-blue;
  }
}

div.row {
  display: flex;
  flex: 1 100%;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px 10px 10px;

  label {
    flex: 2;
    font-size: 14px;
    color: $color--darker-blue;
    font-weight: 500;
  }
}

.ctrl {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 18px;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.ctrl__status {
  position: absolute;
  top: 5px;
  right: 0;
  height: 12px;
  width: 12px;
  background: $color--white;
  border: 1px solid $color--secondary;
  border-radius: 2px;

  .ctrl input:checked ~ & {
    background: $color--primary;
  }

  .ctrl input:disabled ~ & {
    background: $color--light-grey;
    opacity: 0.6;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;

    .ctrl input:checked ~ & {
      display: block;
    }

    .ctrl--checkbox & {
      left: 3px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid $color--white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .ctrl--checkbox input:disabled ~ & {
      border-color: $color--dark-grey;
    }
  }
}

.fade-enter-to,
.fade-leave {
  max-height: 500px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}

.target {
  padding: 10px 10px 0 10px !important;
  transition: all 0.5s;
  overflow: hidden;
  height: auto;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 100%;

  ul {
    width: 100%;
  }

  i {
    top: 37px;
    position: absolute;
    right: 15px;
    font-size: 14px;
    color: $color--light-blue;
  }

  li {
    &:first-child {
      border-top: 1px solid $color--light-grey;
      padding-top: 5px;
    }
  }
}

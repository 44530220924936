
.bulk-upload-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 10px;

  .top-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;

    &.center {
      justify-content: center;
    }

    .left-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  .center-action {
    overflow: hidden;
  }

  .bottom-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  .progress-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .input-file {
    visibility: collapse;
    position: absolute;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}


.checkbox-list-menu {
  $gray-element: rgba(10, 10, 10, 0.2);
  $red-element: rgba(238, 96, 124, 0.733);

  display: flex;

  &.flex-row {
    flex-direction: row;

    .node {
      min-width: 300px; // Columns are not resized when width over specified
    }
  }

  &:not(.flex-row) {
    flex-direction: column;
  }

  .node {
    display: flex;
    flex-direction: column;
    padding: 0 1px 0 0; // Because of scroll overlapping

    &:nth-child(even) {
      > .node-content {
        background: rgb(248, 248, 248);
      }
    }

    .node-content {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0 10px;
      min-height: 30px;
      height: auto;
      width: 100%;
      min-width: 200px;
      user-select: none;
      z-index: 1;
      cursor: pointer;

      .right-side {
        margin-left: auto;
      }

      &.changed {
        color: blue;
        font-weight: bold;
      }

      &:hover {
        background: darken($color: #e0e0e0ce, $amount: 5);
      }

      .arrow-wrap {
        width: 10px;
        display: flex;

        > .arrow {
          display: flex;
          transform: rotate(-90deg);
          transition: transform 0.3s ease;
          filter: grayscale(1);
        }
      }
    }

    &.expanded {
      > .node-content {
        .arrow-wrap {
          .arrow {
            transform: rotate(0deg);
          }
        }
      }
    }

    .action {
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      gap: 10px;

      .btn {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa {
          color: darken($color: $gray-element, $amount: 10);
        }

        &:hover {
          .fa {
            color: lighten($color: royalblue, $amount: 10);
          }
        }
      }

      .delete {
        margin-left: 5px;
        background: $gray-element;

        &:hover {
          background: $red-element;
        }
      }
    }

    &.active {
      background: darken($color: #e0e0e0ce, $amount: 10);
    }

    &.hidden {
      background: rgba(219, 219, 219, 0.335);
      opacity: 0.4;
      color: rgb(39, 39, 39);
    }
  }

  .children {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 0 0 20px;
    border-left: 1px solid #e0e0e0;
  }

  &:not(.vertical) {
    .node {
      min-height: 30px;
    }

    .children {
      overflow: auto;
    }

    &:not(.root) {
      flex-wrap: wrap;
      height: 100%;
      align-content: baseline;

      .node {
        overflow: auto;
      }
    }
  }

  .slide-down-enter-active {
    transition: all 0.4s;
    z-index: 0;
  }

  .slide-down-leave-active {
    transition: none;
  }

  .slide-down-enter {
    transform: translateY(-30px);
    opacity: 0;
  }

  .slide-down-leave-to {
    transform: translateY(-30px);
    opacity: 0;
  }
}

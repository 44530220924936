
.location-client-management {
  > section {
    height: 100%;
    display: flex;
    flex-direction: column;

    .table-wrap {
      display: flex;
      overflow: auto;
      height: 100%;

      table {
        height: fit-content;
      }
    }
  }
}

.loading-container {
  width: 100%;
}

.spacer-top {
  margin: 0 0 20px 0;
}

input {
  box-shadow: none;
  border: 1px solid #9bb4c1;
  height: 37px;
}

.control {
  i {
    top: 10px;
    position: absolute;
    right: 15px;
    font-size: 14px;
  }
}

.center {
  text-align: center;
  height: 100px;
  background: #f0f6f6;

  img {
    width: 50px;
    height: 100px;
  }
}


$lightgray: #7c91ae;
$border-color: #9bb4c1;

.edit-comp {
  form.mt {
    .label {
      color: $lightgray;
      font-size: 12px;
    }
    .input {
      box-sizing: border-box;
      border: 1px solid $border-color;
      border-radius: 4px;
      box-shadow: none;
    }
  }
}


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    .left {
      display: flex;
      gap: 10px;

      label {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .fa {
        font-size: 14px;
        cursor: pointer;

        &:hover {
          color: black;
        }
      }
    }
  }

  > input {
    min-height: 30px;
    height: 30px;
  }

  .parent-check-list {
    max-height: 400px;
    max-width: 350px;
  }

  .checkbox-list-menu {
    overflow: auto;
    height: 100%;
  }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }

  .buttons-centered {
    margin-top: auto;
  }
}

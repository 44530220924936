
.shift-confirm-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0;
  gap: 10px;

  .group {
    width: 100%;
  }

  textarea {
    width: 100%;
    height: 150px;
    overflow: auto;
    resize: vertical;
    padding: 10px;
    font-size: 1.05rem;
    border: silver;
    outline: 1px solid silver;
  }

  .action {
    margin-top: 20px;
  }
}


table {
  margin-top: 20px;
  border: 1px solid #dde6eb;
  border-collapse: separate;
}

table th {
  color: #7c91ae;
  background-color: #f2f7fa;
}

table td {
  color: #405168;
  vertical-align: middle;
  border-bottom: 1px solid #dde6eb;
}

em {
  color: #333;
}

.text-center {
  text-align: center;
  background: #fafafa;
}

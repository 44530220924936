
.confirm-wrap {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  .text {
    margin-bottom: 20px;
    text-align: center;
  }

  .action {
    display: flex;
    justify-content: center;

    > * {
      margin-left: 1rem;
    }
  }
}

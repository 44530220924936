
.multiple-wrap {
  display: flex;
  flex-direction: column;
  gap: 2em 0;
  max-height: 50vh;
  overflow: auto;

  .control {
    display: flex;
    align-items: center;
    gap: 0 0.5em;
  }

  .buttons-centered {
    position: sticky;
    bottom: 0;
    background: #fff;
  }

  .table-wrap {
    display: flex;

    .table {
      width: 100%;
    }
  }
}

.shift {
  display: flex;
  flex-direction: column;
  gap: 0.5em 0;

  .row {
    display: flex;
    align-items: center;
    gap: 0 0.5em;
    width: 100%;

    .control.error {
      color: red;
    }

    .control.success {
      color: green;
    }

    .field {
      width: 50%;
    }
  }
}

.is-suffix {
  white-space: nowrap;
}


.increment-weeks-settings {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 20px 0;
  gap: 20px;

  .title {
    display: flex;
  }

  .content {
    display: flex;
    gap: 20px;
  }
}

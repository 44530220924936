
#client-shift-confirm-page {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 20px;
  position: relative;
  width: 100%;
  height: 100%;

  .sticky-top {
    display: flex;
    // width: 100%; // Don't use max width
    position: absolute;
    right: 0;
    top: -40px;

    .right {
      margin-left: auto;
    }
  }
}

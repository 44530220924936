
#security-tags-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;

  .table-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    width: 50%;
    max-height: 60vh;

    table {
      border-top: none;
      position: relative;
      margin-bottom: 0;
      .remove-wrap {
        .fa {
          margin-left: 5px;
          margin-top: 4px;
          cursor: pointer;
        }
      }
      tbody {
        tr {
          border: 1px solid #dde6eb;
        }
      }
      td {
        min-height: 100%;
      }
    }

    thead {
      tr:first-child {
        th {
          width: 20%;
          height: 36.2px;
          top: 0px;
        }
      }
      tr {
        th {
          top: 36px;
          height: 36.2px; // So it goes behind other row
        }
        th:not(:first-child) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td:not(:first-child) {
          text-align: center;
        }
      }
    }
    tfoot,
    tfoot th,
    tfoot td {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      background: #fff;
      border-top: 1px solid #dde6eb;
      border-bottom: 1px solid #dde6eb;
    }

    th {
      border-top: 1px solid #dde6eb;
      border-bottom: unset;
      position: sticky;
      z-index: 1;
    }
  }

  // ul {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-around;
  //   gap: 14px 10px;
  //   width: 600px;
  //   overflow: auto;

  //   li {
  //     display: flex;
  //     gap: 8px;
  //     align-items: center;

  //     .remove-wrap {
  //       width: 50px;

  //       .fa {
  //         cursor: pointer;
  //       }
  //     }
  //   }

  //   input[type="checkbox"] {
  //     width: 14px;
  //   }

  //   input[type="text"] {
  //     width: 200px;
  //   }
  // }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }
}


#expense-rates {
  display: flex;
  flex-direction: column;

  .filters {
    display: flex;
    margin: 0 0 8px;
  }

  .input {
    width: 80px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    min-height: 49px;

    .client-select {
      width: auto;
      min-width: 350px;
    }

    .category-select {
      width: auto;
      min-width: 220px;
    }

    .bar {
      display: flex;
    }

    .bar.left {
      gap: 10px;
      align-items: flex-start;

      .control {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }

    .bar.right {
      gap: 10px;
      align-items: flex-end;
    }

    .control {
      margin: 0;
    }

    .switch-changed-only {
      .loader {
        border: 5px solid #dbdbdb;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        width: 2rem;
        height: 2rem;
      }

      .switch-wrap {
        margin-right: 5px;
        height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .vue-switcher {
        margin-left: 10px;
      }
    }

    .action {
      display: flex;
      align-items: center;

      &.action-2 {
        margin-left: 10px;
      }
    }
  }

  .table-wrap {
    overflow: auto;
    margin-top: 1em;
    height: 100%;
  }
}

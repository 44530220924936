
#client-invoice-info {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;
  gap: 15px;
  align-items: center;
  height: 100%;
  overflow: hidden;
}


$temp-calendar-worked: #000;
$temp-calendar-booked: #2caa1a;
$temp-calendar-open: #ffee00;
$temp-calendar-filled: #005ce6;
$temp-calendar-available: #3999fa;
$temp-calendar-approval-needed: #e41616;

.shift-row {
  display: flex;
  min-height: 3em;
  align-items: center;

  &:hover {
    background: rgb(245, 245, 245);
    background: linear-gradient(
      90deg,
      rgba(245, 245, 245, 0.2) 0%,
      rgba(245, 245, 245, 0.8) 25%,
      rgba(245, 245, 245, 1) 50%,
      rgba(245, 245, 245, 0.8) 90%,
      rgba(245, 245, 245, 0.2) 100%
    );
  }
}
.legend-icon {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 1em;
  border: 1px solid #80808066;

  &.worked {
    background: $temp-calendar-worked;
  }
  &.booked {
    background: $temp-calendar-booked;
  }
  &.open {
    background: $temp-calendar-open;
  }
  &.filled {
    background: $temp-calendar-filled;
  }
  &.approval-needed {
    background: $temp-calendar-approval-needed;
  }
  &.available {
    background: $temp-calendar-available;
  }
}
.status {
  font-weight: bold;
  margin-right: 0.2em;
}
.separator::before {
  content: "/";
  margin: 0 0.5em;
}
.icon {
  margin-right: 1em;
  min-width: 1.5rem;
  min-height: 1.5rem;
}
.created-by {
  color: #384453;
  margin-right: 1rem;
}
.right-side {
  margin-left: auto;
  display: flex;
  align-items: center;

  > * {
    margin-left: 1rem;
  }

  .book-shift {
    font-weight: bold;
    border: thin solid #858585;

    &:hover {
      transition: all 0.1s ease;
      background: darken($color: #fff, $amount: 5);
    }
  }
}

.redirect {
  color: gray;
  cursor: pointer;

  &:hover {
    color: silver;
  }
}


.table-wrap {
  display: flex;
  overflow: auto;

  .table {
    tr.private {
      background: #f7f7f7;
    }

    &.sticky {
      border-collapse: separate;
      border-spacing: 0;
    }
  }
}


.rates-manage-default-rates {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  gap: 20px;

  .active-date {
    min-width: 100px;

    ::v-deep {
      input {
        // height: 100%;
        height: 40px;
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .client-select {
      width: auto;
      min-width: 350px;
    }

    .category-select {
      width: auto;
      width: 300px;
    }

    .bar {
      display: flex;
    }

    .bar.left {
      gap: 10px;
      align-items: flex-start;

      .control {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      button {
        align-self: flex-end;
      }
    }

    .bar.right {
      gap: 10px;
      align-items: flex-end;
    }
  }

  .filters-2 {
    display: flex;
    gap: 5px;

    .right-side {
      margin-left: auto;
    }
  }

  .bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    height: 100%;

    > *:not(.loader) {
      min-width: 350px;
      height: 100%;
      // max-height: calc(100vh - 400px); // Fixed
      width: 100%;
    }
  }

  .switch-changed-only {
    .loader {
      border: 5px solid #dbdbdb;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      width: 2rem;
      height: 2rem;
    }

    .switch-wrap {
      margin-right: 5px;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 150px;
    }

    .vue-switcher {
      margin-left: 10px;
    }
  }

  .control {
    margin: 0;
  }

  .action {
    display: flex;
    align-items: center;

    &.action-2 {
      margin-left: 10px;
    }
  }

  .loading-wrapper {
    &.mer {
      padding: 32px 0 0 0;
    }
  }

  .action-col {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

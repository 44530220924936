
.gnib-doc {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;

  .label {
    width: 100%;
  }
}

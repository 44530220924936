
.stripe.stripe--expiry.date-picker--override {
  .cov-datepicker {
    background-color: #e5ecf0;
    font-weight: bold;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    text-align: right;
  }
}


#rates-manage-rates-table {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .loader {
    $size: 130px;
    width: $size;
    height: $size;
    max-height: $size;
    max-width: $size;
  }

  .loader-small {
    width: 30px;
    height: 30px;
    max-height: 30px;
    max-width: 30px;
  }

  ul {
    overflow: auto;
    height: 100%;
  }

  .table-wrap {
    overflow-y: auto;
    // overflow-x: hidden;
    overflow-x: auto;
    width: 100%;
    min-height: 400px;

    .rates-table {
      $dyn-size: 190px;
      border-radius: 0;
      border-top: unset;

      th {
        border-top: 1px solid #dde6eb;
        padding: 2px 2px 2px 5px;
        position: sticky;
        top: 0px;
        z-index: 49; // Because of dropdown [z-50]
        // z-index: 100; // Because of dropdown [z-50]

        .column-select {
          font-weight: 400;
          font-size: inherit;
        }

        &#dynamic-column {
          .multiselect {
            width: $dyn-size;
            max-width: $dyn-size;
          }
        }
      }

      tr {
        $cat-size: 240px;
        $inc-size: 160px;

        .info-updated-tooltip {
          // border-radius: 10px;
          border: 1px solid transparent;
          color: white;
          font-weight: 600;
        }

        td {
          padding: 2px 2px 2px 5px;
          input {
            height: 40px;
            background: transparent;
            border: 1px solid transparent;
            box-shadow: none;
            padding: 0 5px;
            border: 1px solid rgba(192, 192, 192, 0.4);
            &:focus {
              border: 1px solid rgba(192, 192, 192, 0.4);
            }

            &:disabled,
            &.disabled {
              background-color: #ededed;
            }
          }
          .timeContainer {
            border: none;
          }
          &.wid-33 {
            min-width: 30px;
          }
          // > input:not(.wid-100) {
          //   // width: 120px;
          // }

          &.blank {
            background: silver;
          }
        }

        &.changed {
          background: #ffff2557;
        }

        .category-select-wrap {
          min-width: $cat-size;
          max-width: $cat-size;

          .category-select {
            font-weight: 400;
            font-size: inherit;
          }
        }

        .increments-select-wrap {
          min-width: $inc-size;
          max-width: $inc-size;

          .increments-select {
            font-weight: 400;
            font-size: inherit;
          }
        }
      }
    }
  }

  .loading-blocker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-text {
    text-align: center;
    height: 150px;
    background: #f0f6f6;
    width: 50px;
  }

  .fade-enter-active {
    transition: all 0.3s ease;
  }

  .fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .fade-enter,
  .fade-leave-to {
    transform: translateX(30px);
    opacity: 0;
  }

  .undo-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}


// Temp calendar colors
$temp-calendar-available: #3999fa;
$temp-calendar-available-pending: #3995f1;
$temp-calendar-unavailable: #f7a53f;
$temp-calendar-worked: black;
// $temp-calendar-worked: grey;
$temp-calendar-booked: #2caa1a;
$temp-calendar-approval-needed: #e41616;

span.sep {
  border: 1px solid #d6e0e5;
  margin: 0 0.8em;
}

.legend {
  border: none;
  cursor: default;

  &:active {
    box-shadow: none;
  }
}

.temp-calendar-available {
  background-color: $temp-calendar-available !important;
}

.temp-calendar-unavailable {
  background-color: $temp-calendar-unavailable !important;
}

.temp-calendar-worked {
  background-color: $temp-calendar-worked !important;
}

.temp-calendar-booked {
  background-color: $temp-calendar-booked !important;
}

.temp-calendar-approval-needed {
  background-color: $temp-calendar-approval-needed !important;
}

.calendar__cell {
  position: relative;

  &.in-past {
    .availability-toggle {
      display: none;
    }
  }

  &:not(.in-past) {
    .availability-toggle {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 1.5rem;
      color: darkgray;
      width: 10px;
      text-align: right;
      cursor: pointer;

      &:hover {
        color: silver;
      }
    }
  }

  .visual__day.temp-calendar-day--available,
  .visual__night.temp-calendar-night--available {
    background-color: $temp-calendar-available !important;
  }

  .visual__day.temp-calendar-day--available-pending,
  .visual__night.temp-calendar-night--available-pending {
    background-color: $temp-calendar-available-pending !important;

    p {
      font-size: 25px;
    }
  }

  .visual__day.temp-calendar-day--unavailable,
  .visual__night.temp-calendar-night--unavailable {
    background-color: $temp-calendar-unavailable !important;
  }

  .visual__day.temp-calendar-day--booked,
  .visual__night.temp-calendar-night--booked {
    background-color: $temp-calendar-booked !important;
  }

  .visual__day.temp-calendar-day--action-needed,
  .visual__night.temp-calendar-night--action-needed {
    background-color: $temp-calendar-approval-needed !important;
  }

  // Order important
  .visual__day.temp-calendar-day--worked,
  .visual__night.temp-calendar-night--worked {
    background-color: $temp-calendar-worked !important;
  }
}

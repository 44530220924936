
.label--checkbox {
  font-size: 13px;
  display: inline-block !important;
  color: #536278 !important;
}
.forgot-pass {
  float: right;
}

.signin,
.register {
  box-shadow: 0 30px 33px 2px rgba(#aaa, 0.3);
  margin-top: 2em;
  background-color: white;
  width: 310px;
  margin: 5em auto;
  padding: 12px 0 29px;
  border-radius: 5px;
  color: #536278;

  section {
    padding: 0 29px;
  }
  section.section--inputs {
    padding-top: 11px;
  }

  header {
    text-align: center;
    padding: 0 29px 0;
  }

  a.button,
  button {
    width: 100%;
    font-weight: bold;
  }
}
.recoverypass {
  box-shadow: 0 30px 33px 2px rgba(#aaa, 0.3);
  margin-top: 2em;
  background-color: white;
  width: 470px;
  margin: 5em auto;
  padding: 12px 0 29px;
  border-radius: 5px;
  color: #536278;

  section {
    padding: 0 29px;
  }
  section.section--inputs {
    padding-top: 11px;
  }

  header {
    text-align: center;
    padding: 0 29px 0;
  }

  a.button,
  button {
    width: 100%;
    font-weight: bold;
  }
}

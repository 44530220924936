
#rates-manage-rates {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;

    .client-select {
      width: auto;
      min-width: 350px;
    }

    .bar {
      display: flex;
    }

    .bar.left {
      gap: 10px;
      align-items: flex-start;

      .control {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      .rule-book {
        justify-content: flex-end;
      }
    }

    .bar.right {
      gap: 10px;
      align-items: flex-end;
    }
  }

  ::v-deep {
    .multiselect__single {
      overflow: hidden;
    }
  }

  .control {
    margin: 0;
  }

  .action {
    display: flex;
    align-items: center;

    &.action-2 {
      margin-left: 10px;
    }
  }
}

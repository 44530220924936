
.bulk-progress-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .progress-data {
    display: flex;
    align-items: center;
    gap: 20px;

    .loading-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .progress-label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  }
}

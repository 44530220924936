
.report-preview-table {
  display: flex;
  overflow: auto;

  .panel-block {
    margin: 0;
    padding: 0;
  }

  .modal-content {
    width: 80%;
    margin: 0 auto;
    padding: 0px;

    .table {
      margin-top: 20px;
      width: 100%;
    }
  }
}

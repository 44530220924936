
.shift-times-books {
  .shifts-table--body {
    height: auto !important;
    max-height: 150px;

    .shift-row {
      height: 36px;

      input {
        width: 144px;
        cursor: pointer;
      }
      .is-medium {
        font-size: 1rem;
      }

      &.new {
        background-color: rgb(247, 247, 216);
      }
    }
    .shift-row.new {
      input {
        width: 144px;
        cursor: pointer;
      }
      .is-medium {
        font-size: 1rem;
      }
    }
  }

  input {
    align-items: center;
    border: none;
    border-radius: 4px;
    box-shadow: none !important;
    display: inline-flex;
    font-size: 1rem;
    justify-content: flex-start;
    line-height: 1.5;
    padding-left: 0.75em;
    padding-right: 0.75em;
    position: relative;
    vertical-align: top;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    color: #363636;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  .cov-vue-date,
  .datepickbox {
    height: 100%;
    width: 100%;
  }

  .cov-picker-box {
    display: flex !important;
    flex-wrap: wrap;

    > div:first-child {
      width: 100%;
    }

    &.date-list {
      display: flex !important;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}

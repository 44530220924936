
table {
  border: none;

  td {
    border: none;
    background-color: #f2f7fa;
    width: 50%;
    padding: 3px 0px;
    padding-left: 10px;
  }

  th {
    color: #7c91ae;
    text-align: right;
    width: 50%;
    padding: 3px 0px;
    padding-right: 10px;
  }
}

table.bill {
  width: 95%;
  border: none;
  padding: 30px 0px;

  tr {
    height: 42px;
  }

  th {
    background-color: white;
    width: 30%;
  }

  td {
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    color: #2669b0;
    text-align: center;
    width: 80%;

    div {
      box-sizing: border-box;
      display: inline-block;
      width: 50%;
      text-align: center;
    }
  }

  tr:nth-child(even) {
    background-color: red;
  }
}

.closeBtn {
  color: #2669b0;
  border-color: #2669b0;
  margin-top: 0;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.details {
  width: 100%;
}

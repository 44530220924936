
#client-details-page {
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem 0;

  .title {
    display: flex;

    .right-text {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .status-btn {
      margin-left: 20px;
    }
  }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }
}


table {
  border: 1px solid #dde6eb;
  border-collapse: separate;
}

table th {
  background-color: #f2f7fa;
}

table thead th {
  &.check {
    width: 150px;
  }
  &.name {
    width: 250px;
  }
  &.spec {
    width: 25%;
  }
  &.preferred {
    width: 200px;
  }
}

table td {
  font-weight: 500;
  color: #405168;
  vertical-align: middle;
  border-bottom: 1px solid #dde6eb;
}

em {
  color: #405168;
}

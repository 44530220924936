
#client-contact-confirm-add {
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 5px 5px;

    .field {
      display: flex;
      flex-direction: column;
      gap: 5px;

      input:not([type="checkbox"]),
      textarea {
        height: 40px;
      }
    }
  }
}
.buttons-centered {
  button {
    display: flex;
    justify-content: space-evenly;
  }
}
.loading-wrapper {
  padding-top: 3px;
  img {
    height: 20px;
    width: 20px;
  }
}

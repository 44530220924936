
$color--light-gray: #dde6eb;
$color--light-blue: #8399a6;
$color--border: #9bb4c1;

.divider {
  padding: 25px 0 45px 0;
  border-top: 2px solid $color--light-gray;
  min-height: 50px;

  &.below {
    .label {
      margin: 0 5px;
    }
  }
}

.input {
  border: 1px solid $color--light-gray;
  height: 37px;
  width: 200px;

  &::placeholder {
    color: $color--light-blue;
    font-size: 15px;
  }
}

.control {
  i {
    top: 10px;
    position: absolute;
    right: 20px;
    font-size: 14px;
    color: $color--light-blue;
  }
}

.filter {
  .control {
    min-width: 250px;
    max-width: 500px;

    i {
      position: absolute;
      right: 0;
      top: 0;
    }

    .label {
      margin-left: 5px;
    }

    .status,
    .subcat {
      display: flex;
      align-items: center;

      .info {
        border-radius: 50%;
        border: 1px solid silver;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 27px;
        min-height: 27px;
        width: 27px;
        height: 27px;

        .fa {
          color: #7c91ae;
        }
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;

  .date-dropdown {
    width: 180px;
  }
}

.field {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  label {
    margin: 0 1em;
  }
}

.nivelate {
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.interval {
  .field {
    float: right;
  }
}

.label {
  color: #7c91ae;
}

.help {
  .is-danger {
    position: relative;
    left: 150px;
    margin-bottom: 20px;
  }
}

.error {
  width: 100%;
  text-align: right;

  p {
    margin-right: 150px;
    margin-bottom: 10px;
  }
}

.field {
  .label {
    position: relative;
    top: -5px;
  }
}

.columns {
  margin-top: 0;

  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .right-side {
    display: flex;
    align-items: flex-start;

    .column {
      align-items: center;
      flex-direction: row;
      gap: 10px;

      label {
        margin-bottom: 0;
      }
    }
  }
}

.divider {
  margin-top: 40px;
  padding: 10px 0 20px 0;
  border-top: 2px solid $color--light-gray;
  border-bottom: 2px solid $color--light-gray;
  min-height: 150px;

  .breaker {
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: $color--light-gray;
    right: 0;
    top: 35px;
  }
}

@media (max-width: 768px) {
  .breaker {
    display: none;
  }
}

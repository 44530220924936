
#permission-settings-page {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 10px 0;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .title {
    display: flex;
    justify-content: center;
  }

  .content2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0px 0;
    gap: 20px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    min-width: 300px;
    width: 100%;

    .bar {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      width: 100%;

      .group {
        width: 300px;
      }

      input {
        min-height: 40px;
        width: 300px;
        margin: 0 auto;
      }

      .changed-perm-group {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
}


.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.modal-content {
  overflow: visible;
}


.file-row {
  &.valid {
    color: green;
  }

  &:not(.valid) {
    color: red;
  }

  .name {
    display: flex;
    gap: 10px;

    .fa-times {
      display: flex;
      align-items: center;
      color: #b9b3aa;
      cursor: pointer;

      &:hover {
        color: darkred;
      }
    }
  }
}


#rates-finalize {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;

  .date-section {
    display: flex;
    justify-content: center;
    gap: 10px;

    .start-date,
    .end-date {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .hide-text {
        ::v-deep input {
          text-indent: 10000px;
        }
      }
    }

    .uni-date-picker {
      width: 100%;
      height: auto;
    }
  }

  .notice {
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: column;
    gap: 3px;

    .text {
      margin: 0;
    }
  }

  .text {
    margin: 20px 0;
    text-align: left;
  }

  .list {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: rgb(255, 255, 156);

    .item {
      .error {
        color: crimson;
      }
    }
  }

  .action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 20px;

    .top {
      display: flex;

      ::v-deep {
        .report {
          gap: 10px;
        }

        button {
          width: 200px;
        }
      }
    }

    .bottom {
      display: flex;
      gap: 10px;

      button {
        width: 100px;
      }
    }
  }
}

.indef-ckbox {
  display: flex;
  // gap: 10px;
  height: 12px;
  align-items: center;
  justify-content: flex-start;

  input {
    width: auto !important;
  }
}

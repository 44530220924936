@mixin calendar-shared {
  // Colors
  $calendar-neutral: #ebeff1;
  $calendar-font-color: #2d5764;

  $calendar-pale: #f2f7fa;
  $calendar-blue: #0077e5;
  $calendar-red: #e41616;

  $calendar-light-green: #16c03d; // Used previously for open
  $temp-calendar-open: #ffee00;
  $temp-calendar-filled: #005ce6;
  // $calendar-dark-green: #14b338;

  $calendar-light-grey: #c4cfd4;
  $calendar-dark-grey: #b5c1c7;

  /* easier naming for less confusion  */
  $filter-open: $temp-calendar-open;
  $filter-filled: $temp-calendar-filled;
  $filter-action-needed: $calendar-red;

  h1.title.is-3 {
    margin-bottom: 2px;
  }
  .toggle {
    margin-bottom: 1.5em;
  }
  .has-horspace-layout {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1em auto;

    .client-select {
      margin-right: 10px;
    }

    .input-wrap {
      margin-top: 0.75rem;
      display: flex;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        width: 50px;
        height: 28px;
        margin: 0 0 0 1rem;

        > .fa {
          margin: 0;
          top: 0;
          cursor: pointer;
          font-size: 1.2rem;
        }
      }
    }
  }
  .filter-toggles {
    .is-active {
      border: none;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.8);
      background-color: #cbdadc;
    }
    .is-squared {
      width: 15px;
      height: 15px;
      margin-right: 0.5em;
    }
    .is-open {
      background-color: $filter-open;
    }
    // .is-grey {
    //   background-color: $calendar-light-grey;
    // }
    .is-filled {
      background-color: $filter-filled;
    }
    .is-approval {
      background-color: $filter-action-needed;
    }
  }

  ul.api {
    overflow: auto;
  }
  li.inline {
    background-color: $calendar-pale;
    float: left;
    padding: 0.3em 0.6em;
    border: 1px solid darken($calendar-pale, 10%);
  }
  .separator {
    margin: 20px 0;
    clear: both;
  }
  .calendar__root {
    clear: both;
    // lost-center: 800px;
  }
  .calendar__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $calendar-pale;
    border-radius: 6px 6px 0 0;
    padding: 1.1em;
    .button {
      background-color: rgba(255, 255, 255, 0.6);
      border: 1px solid #becdda;
      min-width: 80px;
    }
    .button:not(:first-child),
    .button:not(:last-child) {
      margin: 0 0.5em;
    }
    .button-container {
      flex: 1;
    }
    .button-container:last-child {
      text-align: right;
    }
    .header__label {
      text-align: center;
      font-size: 18px;

      p {
        display: flex;
        align-items: center;
      }

      input {
        font-size: 16px;
        width: 90px;
      }
    }
  }
  .calendar__weekdays {
    background-color: $calendar-pale;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .calendar__cell {
    lost-column: 1/7 7 5px;
    background-color: $calendar-neutral;
    height: 110px;
    margin-bottom: 5px;
    display: flex;

    &.is-selected-cell {
      outline: 5px solid #294959;
    }

    &.has-no-gap {
      height: 40px;
      justify-content: center;
      align-items: center;
      font-weight: 900;
      color: #2d5764;
    }

    .visual,
    .data {
      flex: 1;
      font-size: smaller;
    }

    .visual {
      display: flex;
      flex-direction: column;

      .visual__day,
      .visual__night {
        background-color: $calendar-light-grey;
        height: 55px;

        > * {
          &.open,
          &.filled {
            width: 50%;
            height: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.open {
            border-right: 1px solid lightgray;
          }
        }
      }

      .visual__day,
      .visual__night {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: white;
        opacity: 0.6; // TODO just testing
        transition: background-color 0.5s;

        .open,
        .filled {
          transition: background-color 0.5s;
        }
      }

      &.client {
        .visual__night {
          background-color: $calendar-dark-grey;
        }

        .visual__day--open {
          .open {
            background-color: $filter-open;
            color: black;
          }
        }

        .visual__night--open {
          .open {
            background-color: darken($filter-open, 11%);
            color: black;
          }
        }

        .visual__day--filled {
          .filled {
            background-color: $filter-filled;
          }
        }

        .visual__night--filled {
          .filled {
            background-color: darken($filter-filled, 11%);
          }
        }

        .visual__day {
          .open.has-approval,
          .filled.has-approval {
            background-color: $filter-action-needed;
          }
        }

        .visual__night {
          .open.has-approval,
          .filled.has-approval {
            background-color: darken($filter-action-needed, 11%);
          }
        }

        .visual__day--all,
        .visual__night--all {
          color: darken(#4b5e6f, 20%);
        }
      }

      &:not(.client) {
        .visual__night {
          background-color: $calendar-dark-grey;
        }

        .visual__day--open {
          background-color: $filter-open;
          color: black;
        }

        .visual__night--open {
          background-color: darken($filter-open, 11%);
          color: black;
        }

        .visual__day--filled {
          background-color: $filter-filled;
        }

        .visual__night--filled {
          background-color: darken($filter-filled, 11%);
        }

        .visual__day--approval_needed {
          background-color: $filter-action-needed;
        }

        .visual__night--approval_needed {
          background-color: darken($filter-action-needed, 11%);
        }

        .visual__day--all,
        .visual__night--all {
          color: darken(#4b5e6f, 20%);
        }
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      color: #4b5e6f;
      font-size: 25px;
      font-weight: 500;
      line-height: 30px;

      .svg-wrap {
        width: 100%;
        margin-right: auto;

        &:first-child {
          width: 16px;
          margin-left: 6px;
        }

        &:last-child {
          width: 12px;
          margin-left: 8px;
        }
      }

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .redirect {
    margin-left: 10px;
  }

  // for all dates in past, less opacity
  .calendar__cell.in-past {
    opacity: 0.4;
  }

  .shift-listing {
    color: #384453;
    border-top: 1px solid rgba(#294959, 0.25);
    border-bottom: 1px solid rgba(#294959, 0.45);
    padding: 1em;
  }

  .is-spaced {
    margin-bottom: 5px;
  }
}

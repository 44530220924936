
.text-error {
  color: crimson;
}

.sub-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

td.editincremets-center {
  text-align: center;
}

th.center {
  text-align: center;
}

.modal-content {
  width: 80%;
  margin: 0 auto;
  padding: 0px;
}


.report-preview {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 10px;

  button {
    float: right;
    margin-bottom: 15px;
  }

  .title {
    text-align: center;
    margin: 10px 0px;
  }

  .subtitle {
    text-align: center;
    margin: 10px 0px;
  }
}


#manage-file-upload {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px 1.5rem 10px;
  gap: 20px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    // width: 500px;
    gap: 10px 10px;
    margin: 0;
  }

  .loader {
    $size: 2rem;
    width: $size;
    height: $size;
    max-height: $size;
    max-width: $size;
  }
}

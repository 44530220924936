
.create-holiday-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  .text-message {
    text-align: center;
    padding: 10px 50px 5px 50px;
  }
  .create-holiday-inputs {
    display: flex;
    flex-direction: row;
    margin: 10px 0px 5px 0px;
    .hol {
      margin: 0 5px 0 5px;
    }
  }
  .warn-msg {
    color: red;
  }
  .confirm {
    button {
      margin: 0 10px 5px 10px;
    }
  }
}

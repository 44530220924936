
.backup-fail {
  margin: 10px auto;
  width: 90%;
  text-align: center;
}

.backup-fail p {
  margin: 8px;
}


.shift-confirm-interval-form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // max-height: 500px;
    // overflow: auto;

    .rows {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .row {
        display: flex;
        gap: 10px;
        align-items: flex-end;

        .group-input {
          display: flex;
          flex-direction: column;
        }

        .info {
          margin: 0 20px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .remove-wrap {
          align-self: flex-end;
          margin: 0 0 4px 10px;

          img {
            cursor: pointer;
          }
        }

        input {
          width: 100px;

          &.error {
            background: #ffedf0;
            border: 1px solid crimson;
          }
        }
      }
    }

    button {
      align-self: flex-start;
    }
  }

  .action {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
}


.details {
  width: 100%;
}

.cancel {
  border-radius: 20px;
  width: 24px;
  height: 24px;
  font-size: 9px;
  line-height: 24px;
  margin-top: 14px;
  color: white;
  display: block;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.confirm {
  border-radius: 20px;
  background-color: #3eef76;
  width: 24px;
  height: 24px;
  font-size: 9px;
  line-height: 24px;
  margin-top: 12px;
  color: white;
  display: block;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.field {
  margin-right: 5px;

  .input {
    height: 36px;
  }

  &.shift-name {
    flex-grow: 2;
  }
}


.uni-tabs {
  $gray-color: #7c7d86;

  user-select: none;
  align-items: stretch;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  // overflow: hidden;
  // overflow-x: auto;
  white-space: nowrap;
  position: relative;

  a {
    width: 100%;
    text-align: center;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    gap: 5px;

    align-items: center;
    justify-content: center;
    color: $gray-color;
    margin-bottom: -1px;
    padding: 0.5em 0;

    &.inactive {
      opacity: 0.4;
    }

    &:hover {
      color: lighten($color: $gray-color, $amount: 10);
      transition: all 0.2s ease;
    }

    .line {
      height: 3px;
      width: 100%;
      background-color: $gray-color !important;
    }
  }

  @mixin colorTab {
    color: var(--color-primary-10) !important;

    .line {
      background-color: var(--color-primary-10) !important;
    }
  }

  a {
    &.is-active.native-active:not(.is-custom-active) {
      @include colorTab;
    }

    &.is-custom-active {
      @include colorTab;
    }
  }

  &::-webkit-scrollbar {
    height: 0.7em;
  }
}


.journal-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
  .uni-tabs-wrap {
    width: 100%;
    padding: 20px 0 10px 0;
  }

  .journal-table-wrap {
    display: flex;
    width: 100%;
  }

  .journal-filters-wrap {
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  .journal-export-wrap {
    display: flex;
    // flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    margin-left: auto;
    border: 1px solid silver;
    padding: 10px;

    .switch-wrapper {
      display: flex;
      flex-direction: column;

      label {
        margin: 0;
      }
    }

    .btn-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .info {
      border-radius: 50%;
      border: 1px solid silver;
      margin: 0 0 5px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 27px;
      min-height: 27px;
      width: 27px;
      height: 27px;
      .fa {
        color: #7c91ae;
      }
    }

    .label {
      margin: 0 2px 8px 0;
      white-space: nowrap;
    }
  }

  .journal-table-wrap {
    overflow: auto;
    min-height: 100px;

    .spinner-wrap {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }

  .journal-middle-section {
    display: flex;
    gap: 20px;
    // flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 768px) {
    align-items: flex-start;

    .journal-table-wrap {
      min-height: 200px;
    }
  }
}
.export-status-tooltip {
  // border-radius: 10px;
  border: 1px solid transparent;
  color: white;
  font-weight: 600;
}

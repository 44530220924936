
.journal-form {
  display: flex;
  gap: 10px;
  min-width: 800px;
  border: 1px solid silver;
  padding: 20px;
  position: relative;

  &.anima {
    animation-name: flash;
    animation-duration: 2s;
  }

  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    // width: 100%;
    width: max-content;

    .top-section {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .upload-wrapper {
    min-width: 200px;
  }

  .right-side {
    // width: 100%;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .control {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      textarea {
        min-height: 170px;
        height: 100%;
      }
    }

    .char-number-overlay {
      position: absolute;
      // bottom: 5px;
      // right: 20px;
      bottom: 12px;
      right: 12px;
      padding: 0 3px;
      background: rgba(0, 0, 0, 0.2);
      color: white;
      user-select: none;

      &.offset {
        bottom: 32px;
      }
    }
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .private-checkbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .label {
        white-space: nowrap;
      }
    }

    .label {
      display: flex;
      align-items: center;
      margin: 0;
    }

    span {
      font-size: 14px;
    }

    .button {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    > .button:last-child {
      margin-left: auto;
    }
  }

  .progress-bar {
    display: flex;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;

    .line {
      background: var(--color-primary);
    }
  }

  @keyframes flash {
    0% {
      background-color: rgba(218, 214, 33, 0.363);
    }
    100% {
      background-color: transparent;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    min-width: unset;
  }
}


.copy-holiday-wrapper {
  display: flex;
  flex-direction: column;
  .text-message {
    text-align: center;
    padding: 10px 50px 5px 50px;
  }
  .from-to {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px 0 15px 0;
    .from {
    }
    .to {
      .input {
        min-height: 39px;
        min-width: 277px;
      }
    }
  }
  .warn-msg {
    color: red;
    display: flex;
    justify-content: center;
  }
  .submit {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0 5px 0;
  }
}


nav.nav {
	min-height: 5.5rem;
	position: fixed;
	width: 100%;
	top: 0;
}
.nav-item img {
	max-height: 100%;
}
.nav-item--toggle {
	padding: 0 1.4em;
}
.nav-item--expanded {
	padding: 0 2.5em;
}
.nav-item--sep {
	border-right: 2px solid #eee;
}

.menu-label {
	padding: 1.8em 1em;
}

.menu-list li {
	display: flex;
	align-items: center;
	justify-content: center;
}
.menu-list li a {
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 1em 0;
	border-radius: 0;
	width: 100%;
	min-height: 90px;
	transition: all 0.3s ease;
}

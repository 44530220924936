
.table-wrap {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;

  .pagination {
    justify-content: right;
  }
  .table {
    tr.private {
      background: #f7f7f7;
    }

    .subject-column {
      min-width: 200px;
    }
    .client-column,
    .temp-column {
      min-width: 200px;
    }
    .has-pre-tag {
      max-width: 1000px;

      span {
        width: 100%;
        pre {
          width: 100%;
          background: transparent;

          white-space: pre-wrap; /* css-3 */
          white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
          white-space: -pre-wrap; /* Opera 4-6 */
          white-space: -o-pre-wrap; /* Opera 7 */
          word-wrap: break-word;

          font-family: "Lato", sans-serif;
          font-size: 1rem;
        }
      }
    }

    &.sticky {
      border-collapse: separate;
      border-spacing: 0;
    }
  }
}

$button: $grey-darker !default;
$button-background: $white !default;
$button-border: $grey-lighter !default;

$button-hover: $link-hover !default;
$button-hover-border: $link-hover-border !default;

$button-focus: $link-focus !default;
$button-focus-border: $link-focus-border !default;

$button-active: $link-active !default;
$button-active-border: $link-active-border !default;

$button-shadow-inset: inset 0 1px 2px rgba($black, 0.2);

@function buttonIconSpacing($button-font-size, $icon-width) {
  // The button font-size value with no unit
  $button-value: removeUnit($button-font-size);

  // The rem height of the button
  // based on a height of 2.5em
  $button-height: 2.5rem * $button-value;

  // The rem total horizontal padding of the button
  $button-horizontal-padding: 2 * 0.75rem * $button-value;

  // For the icon center to align with the button center
  // the horizontal padding + the icon width must equal the button height
  // $button-height = $button-horizontal-padding + $icon-width + $difference
  $difference: $button-height - $button-horizontal-padding - $icon-width;

  @return $difference / 2;
}

@mixin button-icon($button-font-size) {
  $small-offset: buttonIconSpacing($button-font-size, 1rem);
  $normal-offset: buttonIconSpacing($button-font-size, 1.5rem);
  $medium-offset: buttonIconSpacing($button-font-size, 2rem);
  $large-offset: buttonIconSpacing($button-font-size, 3rem);

  .icon {
    &:first-child:not(:last-child) {
      margin-left: $normal-offset;
      margin-right: $button-font-size / 2;
    }

    &:last-child:not(:first-child) {
      margin-left: $button-font-size / 2;
      margin-right: $normal-offset;
    }

    &:first-child:last-child {
      // The -1px is to account for the button 1px border
      margin-left: calc(-1px + #{$normal-offset});
      margin-right: calc(-1px + #{$normal-offset});
    }

    &.is-small {
      &:first-child:not(:last-child) {
        margin-left: $small-offset;
      }

      &:last-child:not(:first-child) {
        margin-right: $small-offset;
      }

      &:first-child:last-child {
        margin-left: calc(-1px + #{$small-offset});
        margin-right: calc(-1px + #{$small-offset});
      }
    }

    &.is-medium {
      &:first-child:not(:last-child) {
        margin-left: $medium-offset;
      }

      &:last-child:not(:first-child) {
        margin-right: $medium-offset;
      }

      &:first-child:last-child {
        margin-left: calc(-1px + #{$medium-offset});
        margin-right: calc(-1px + #{$medium-offset});
      }
    }

    &.is-large {
      &:first-child:not(:last-child) {
        margin-left: $large-offset;
      }

      &:last-child:not(:first-child) {
        margin-right: $large-offset;
      }

      &:first-child:last-child {
        margin-left: calc(-1px + #{$large-offset});
        margin-right: calc(-1px + #{$large-offset});
      }
    }
  }
}

// The button sizes use mixins so they can be used at different breakpoints
@mixin button-tiny {
  border-radius: 3px;
  font-size: $size-small;

  @include button-icon($size-tiny);
}

@mixin button-small {
  border-radius: $radius-small;
  font-size: $size-small;

  @include button-icon($size-small);
}

@mixin button-medium {
  font-size: $size-medium;

  @include button-icon($size-medium);
}

@mixin button-large {
  font-size: $size-large;

  @include button-icon($size-large);
}

.button {
  &.is-info {
    background-color: var(--color-primary-10) !important;
    color: white;

    &:hover {
      background-color: var(--color-primary-20) !important;
    }
  }

  &.is-generic-app-blue:not(.is-outlined) {
    background-color: var(--color-primary) !important;

    &:focus,
    &:hover {
      background-color: var(--color-primary-20) !important;
    }
  }

  &.is-generic-app-blue.is-outlined {
    background-color: transparent;
    border-color: var(--color-primary-10) !important;
    color: var(--color-primary-10) !important;

    &:focus,
    &:hover {
      color: var(--color-primary-30) !important;
      background-color: transparent !important;
    }
  }

  &.is-generic-app-light-blue {
    background-color: var(--color-primary-10);

    &:focus,
    &:hover {
      background-color: var(--color-primary-20);
    }
  }
}

.button {
  @include control;
  @include unselectable;

  background-color: $button-background;
  border: 1px solid $button-border;
  color: $button;
  cursor: pointer;
  justify-content: center;
  padding-left: 0.75em;
  padding-right: 0.75em;
  text-align: center;
  white-space: nowrap;

  strong {
    color: inherit;
  }

  @include button-icon($size-normal);

  // States
  &:hover,
  &.is-hovered {
    border-color: $button-hover-border;
    color: $button-hover;
  }

  &:focus,
  &.is-focused {
    border-color: $button-focus-border;
    box-shadow: 0 0 0.5em rgba($button-focus-border, 0.25);
    color: $button-focus;
  }

  &:active,
  &.is-active {
    border-color: $button-active-border;
    box-shadow: $button-shadow-inset;
    color: $button-active;
  }

  // Colors
  &.is-link {
    background-color: transparent;
    border-color: transparent;
    color: $text;
    text-decoration: underline;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      background-color: $background;
      color: $text-strong;
    }
  }

  @each $name, $pair in $colors {
    &.is-#{""+$name} {
      background-color: var(--color-primary-20);
      border-color: transparent;
      color: var(--color-primary-10);

      &:hover,
      &.is-hovered {
        background-color: var(--color-primary-d20);
        border-color: transparent;
        color: var(--color-primary-10);
      }

      &:focus,
      &.is-focused {
        border-color: transparent;
        box-shadow: 0 0 0.5em rgba(var(--color-primary-20), 0.25);
        color: var(--color-primary-10);
      }

      &:active,
      &.is-active {
        background-color: var(--color-primary-d30);
        border-color: transparent;
        box-shadow: $button-shadow-inset;
        color: var(--color-primary-10);
      }

      &.is-inverted {
        background-color: var(--color-primary-10);
        color: var(--color-primary-20);

        &:hover {
          background-color: var(--color-primary-d30);
        }
      }

      &.is-loading {
        &:after {
          border-color: transparent
            transparent
            var(--color-primary-10)
            var(--color-primary-10) !important;
        }
      }

      &.is-outlined {
        background-color: transparent;
        border-color: var(--color-primary-20);
        color: var(--color-primary-20);

        &:hover,
        &:focus {
          background-color: var(--color-primary-20);
          border-color: var(--color-primary-20);
          color: var(--color-primary-10);
        }
      }

      &.is-inverted.is-outlined {
        background-color: transparent;
        border-color: var(--color-primary-10);
        color: var(--color-primary-10);

        &:hover,
        &:focus {
          background-color: var(--color-primary-10);
          color: var(--color-primary-20);
        }
      }
    }
  }

  // Sizes
  &.is-tiny {
    @include button-tiny;
  }

  &.is-small {
    @include button-small;
  }

  &.is-medium {
    @include button-medium;
  }

  &.is-large {
    @include button-large;
  }

  // Modifiers
  &[disabled],
  &.is-disabled {
    opacity: 0.5;
  }

  &.is-fullwidth {
    display: flex;
    width: 100%;
  }

  &.is-loading {
    color: transparent !important;
    pointer-events: none;

    &:after {
      @include loader;
      @include center(16px);

      position: absolute !important;
    }
  }
}


.error-list {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  min-height: 400px;
  height: 100%;

  .table {
    border-top: unset;
  }

  thead {
    tr:first-child {
      th {
        top: 0px;
        height: 36.2px; // So it goes behind other row
      }
    }

    tr:not(:first-child) {
      th {
        top: 36px;
        border-bottom: 1px solid #dde6eb;
      }
    }
  }

  th {
    border-top: 1px solid #dde6eb;
    border-bottom: unset;
    position: sticky;
    z-index: 50;

    &.status-msg.has-error {
      min-width: 420px;
      max-width: 600px;
    }
  }

  td {
    &.status-msg {
      &.has-error {
        max-width: 600px;
        word-break: break-word;
      }

      .error {
        color: darkred;
      }

      .success {
        color: green;
      }
    }
  }
}


$color--light-blue: #8399a6;
.control {
  i {
    top: 10px;
    position: absolute;
    right: 20px;
    font-size: 14px;
    color: $color--light-blue;
  }
}

.icon-Billing {
  margin-top: 10px;
  margin-right: 5px;
}


.manage-regions-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .content {
    display: flex;
    gap: 30px 40px;
    margin: 20px 0;
    width: 100%;

    .left-side,
    .right-side {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: center;
      width: 100%;
    }

    .chlist {
      > .checkbox-list-menu {
        overflow: auto;
        // height: 100%;
        height: 60vh;
        padding-right: 4px;
        width: 100%;
      }
    }
  }

  footer {
    display: flex;
    gap: 20px;
    margin: 20px 0 0;
  }
}

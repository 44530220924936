
.three-filter {
  display: flex;

  .field {
    max-width: 700px;
  }

  p.control {
    margin-right: 1em;
  }
}


.details-round-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .form-section {
    display: flex;
    flex-direction: column;
  }

  .extra-form-fields {
    display: flex;
    flex-direction: column;

    .label {
      min-width: 300px;
      width: 300px;
    }
  }

  .group {
    display: flex;

    > * {
      border-left: 1px solid silver;
      border-top: 1px solid silver;

      &:last-child {
        border-right: 1px solid silver;
      }
    }

    &:last-child {
      > * {
        border-bottom: 1px solid silver;
      }
    }

    .first-col {
      padding: 3px;

      .label {
        padding: 0;
      }

      .small-text {
        font-size: 0.7rem;
      }

      .checkbox-group {
        color: unset;
      }
    }

    .label {
      min-width: 150px;
      margin-bottom: 0;
      padding: 3px;
    }

    label {
      display: flex;
      align-items: center;
      margin: 0;
    }

    .value {
      flex: 1;
      padding: 3px;
      display: flex;
      align-items: center;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
      gap: 5px;

      input {
        height: 40px;

        &::placeholder {
          font-weight: 500;
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  :deep() {
    .simplert__title {
      font-weight: 500;
    }

    .simplert__body {
      font-size: 1.3rem;

      > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}


.edit-holiday-wrapper {
  display: flex;
  flex-direction: column;
  .text-message {
    text-align: center;
    padding: 10px 50px 5px 50px;
  }
  .inputs {
    gap: 20px;
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: center;
  }
  .warrning-message {
    display: flex;
    justify-content: center;
    color: red;
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0 5px 0;
  }
}


#rates-finalize {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;

  .text {
    margin: 20px 0;
  }

  .action {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    > *:first-child {
      margin-right: 10px;
      padding: 0 16px;
    }
  }
}


.manage-user {
  width: 100%;
  padding: 20px 4px;

  .hideMessagePasswordConfirmation {
    color: red;
    display: none;
  }
  .showMessagePasswordConfirmation {
    color: red;
    display: block;
    text-align: center;
  }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost-shift {
    width: calc(99.9% * 2 / 3 - (0px - 81px * 2 / 3));
    margin-left: calc(
      99.9% * (-1 / 6 * -1) - (63px - 31px * (-1 / 6 * -1)) + 30px
    ) !important;
  }
  span.sep {
    border: 1px solid #d6e0e5;
    margin: 0 0.8em;
  }

  &:not(.edit-user) {
    & > .title {
      display: none;
    }
  }
}

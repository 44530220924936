
#category-tree-node {
  $gray-element: rgba(10, 10, 10, 0.2);
  $red-element: rgba(238, 96, 124, 0.733);

  .node {
    background: transparent;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    > .arrow {
      display: flex;
      margin-right: 5px;
      transform: rotate(-90deg);
      transition: transform 0.3s ease;
      filter: grayscale(1);

      &.expanded {
        transform: rotate(0deg);
      }
    }

    .action {
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      gap: 10px;

      .btn {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa {
          color: darken($color: $gray-element, $amount: 10);
        }

        &:hover {
          .fa {
            color: lighten($color: royalblue, $amount: 10);
          }
        }
      }

      .delete {
        margin-left: 5px;
        background: $gray-element;

        &:hover {
          background: $red-element;
        }
      }
    }

    &.active {
      background: darken($color: #e0e0e0ce, $amount: 10);
    }

    &.hidden {
      background: rgba(219, 219, 219, 0.335);
      opacity: 0.4;
      color: rgb(39, 39, 39);
    }

    &:hover {
      background: darken($color: #e0e0e0ce, $amount: 5);
    }
  }

  .children {
    padding: 0 0 0 20px;
    border-left: 1px solid #e0e0e0;
  }

  .slide-down-enter-active {
    transition: all 0.4s;
  }

  .slide-down-leave-active {
    transition: none;
  }

  .slide-down-enter {
    transform: translateY(-30px);
    opacity: 0;
  }

  .slide-down-leave-to {
    transform: translateY(-30px);
    opacity: 0;
  }
}

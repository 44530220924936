
#general-receipt-manage {
  display: flex;
  flex-direction: column;
  padding: 20px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: auto;
    max-width: 400px;
    width: 100%;
    margin: auto;

    li {
      list-style-type: none;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

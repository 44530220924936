
#client-finances-info {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  width: 100%;
  gap: 15px;
  align-items: center;
  height: 100%;
  overflow: hidden;

  .finances-table {
    display: flex;
    overflow: auto;
    height: 100%;
    align-items: flex-start;
  }

  .top-action {
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 51;

    .bar {
      display: flex;
      align-items: flex-end;
      gap: 10px;
    }

    .category-select,
    .subcategory-select {
      width: auto;
      min-width: 300px;
    }

    .switch-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 10px;
    }
  }
}


#expenses-table {
  height: 100%;
  border: 1px solid #e2e7ea;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

span {
  font-weight: 400;
}

.filters {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  padding: 10px 20px;
  width: 100%;

  .left-side {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-right: auto;
  }

  .right-side {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    margin-left: auto;
  }
}

.shift-type-td {
  color: #aeafb7;

  img {
    max-width: 27px;
    max-height: 27px;
    width: auto;

    &.onCall {
      $width: 40px;
      width: $width;
      max-width: $width;
      max-height: $width;
      margin-bottom: -5px;
      margin-left: -7px;
    }
  }
}

em {
  color: #333;
}

svg {
  margin-left: 0.5em;
  cursor: pointer;
}

.filter_dropdown {
  z-index: 2;
  position: absolute;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0 5px 0 rgba(black, 0.3);
  left: 0;
  background-color: white;
  padding: 1em;
}

.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.center-text {
  text-align: center;
  height: 150px;
  background: #f0f6f6;

  img {
    width: 50px;
  }
}

.button-wrap {
  display: flex;
  gap: 10px;
}

// Fixes table headers with sort buttons layout.
th,
.flex-header.column-label {
  font-size: 13px;
}

.flex-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .column-label {
    flex: 0 1;
  }

  .sort-buttons {
    flex: 1 0 auto;
    margin-left: 3px;
  }
}

tr {
  &.EREC {
    .generic-app-tag {
      background: #cde8e6;
    }
  }

  &.EMLG {
    .generic-app-tag {
      background: #dbe4f0;
    }
  }

  &.ESUB {
    .generic-app-tag {
      background: #e2f2fa;
    }
  }
}

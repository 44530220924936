
.register-temp-increments {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
}

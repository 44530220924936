
#notitcation-centre-load-templates {
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-height: 35vh;
  padding: 1em 0 2em 0;
  .template-name {
    display: flex;
    flex-direction: column;
  }
  .content-wrap {
    display: flex;
    flex-direction: column;
    .textarea {
      height: 300px;
      overflow: auto;
    }
  }
  .button-line {
    display: flex;
    justify-content: center;
    gap: 2em;
  }
  .spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
    img {
      width: 10%;
    }
  }
  h2 {
    text-align: center;
  }
  textarea {
    resize: none;
  }
}

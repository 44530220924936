
#expenses {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .tab-wrapper {
    display: flex;
    justify-content: center;
  }

  .pagination {
    margin-top: 20px;
    align-self: flex-end;
  }
}

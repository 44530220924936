
.list-of-workers {
  display: flex;
  flex-direction: column;
  gap: 0em;
  padding: 15px;
  .section {
    padding-left: 0px;
  }
  .content {
    margin: 30px 0 0px 0;
    text-align: center;
    h2 {
      font-size: 14px;
      font-weight: 600;
    }
    h4 {
      text-align: left;
      margin: 5px 0 -10px 0;
      font-size: 14px;
    }
    .input-bar {
      .input {
        width: 250px;
        font-size: 14px;
      }
    }
  }
  .loading-container.center {
    text-align: center;
    margin: 0 0 10px 0;
  }
  .pagination {
    margin: 0 auto;
    margin-bottom: 5px;
    .is-centered:deep(.pagination-list) {
      list-style: none !important;
      margin: 0 0 0 0;
    }
  }
  .content-without-workers {
    text-align: center;
    margin: 20px 0 0 0;
    h3 {
      font-size: 14px;
    }
  }
}


.public-holidays-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  .public-title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .public-year-and-holiday {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    // min-width: 300px;
    .year {
      display: flex;
      flex-direction: column;
      //   background-color: purple;
      color: black;
      #years-select {
        min-height: 40px;
        display: block;
        padding: 8px 40px 0 8px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
        font-size: 14px;
      }
    }
    .holiday {
      display: flex;
      flex-direction: column;

      .label {
        // visibility: hidden;
      }
      button {
        cursor: pointer;
        background-color: #625efd;
        color: white;
        border-radius: 10px;
        border: none;
        padding: 11px;
        font-weight: bold;
        font-size: 14px;
      }
      button:hover {
        background-color: #4c48b4;
      }
    }
    .copy-holiday {
      .label {
      }
      button {
        cursor: pointer;
        background-color: #2669b0;
        color: white;
        border-radius: 10px;
        border: none;
        padding: 11px;
        font-weight: bold;
        font-size: 14px;
      }
      button:hover {
        background-color: #1f4b7a;
      }
    }
  }
  .public-dates {
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding: 0 4px 0 0;
    .left {
      margin: 0 10px 0 0;
      .title {
        background-color: #f0f8fa;
        color: black;
        font-size: 1.2rem;
      }
      .toggle {
        background-color: #f7f7f7;
      }
      .content {
        margin: 10px 0 10px 0;
        // padding: 5px 0 5px 0;
        ul {
          margin: 0 0 0 0;
          .toggle {
            background-color: #f7f7f7;
          }
          li {
            font-size: 1.3rem;
          }
        }
      }
    }
    .right {
      .title {
        background-color: #f0f8fa;
        color: black;
        font-size: 1.2rem;
      }
      .toggle {
        background-color: #f7f7f7;
      }
      .content {
        margin: 10px 0 10px 0;
        // padding: 5px 0 5px 0;
        ul {
          margin: 0 0 0 0;
          .holiday-date {
            display: flex;
            img {
              margin: 0 0 0 10px;
              width: 12px;
              cursor: pointer;
            }
            li {
              list-style: none;
              font-size: 1.3rem;
              // margin: 0 10px 0 0;
            }
          }
        }
      }
    }
  }
}


.sort {
  display: inline-block;
  vertical-align: sub;
  margin-left: 2px;
  color: #aebbcc;

  > i {
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    width: 10px;

    &.active {
      color: var(--color-primary);
    }
  }

  > i:first-child {
    margin-top: 1px;
  }
}

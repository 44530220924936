
.journal-simple-upload {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 3px dashed rgba(0, 0, 0, 0.2);

  .button {
    width: 100%;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 5px;

    li {
      display: flex;
      justify-content: space-between;
      gap: 5px;

      .name {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .remove {
        min-width: 24px;
        width: 24px;
        cursor: pointer;
      }
    }
  }
}

.dragndrop__input {
  visibility: hidden;
}


.file-list-management {
  // height: 100%; // Don't use this, it breaks modal
  width: 100%;
  padding: 10px 0;

  tr {
    &.grouped {
      &:not(.first):not(.last) {
        td {
          border-top: none;
          border-bottom: none;
        }
      }

      &.first {
        td {
          border-bottom: none;
        }
      }

      &.last {
        td {
          border-top: none;
        }
      }
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .fileRow {
    td:first-child {
      font-size: 1.1em;
    }
  }

  table {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;

    td.deleting {
      span {
        color: red;
        text-decoration: line-through;
      }
    }

    .has-icon {
      > span {
        cursor: pointer;
      }
    }

    thead td {
      color: #7c91ae;
      border: none;
      font-weight: 900;
      font-size: smaller;
    }
  }
}


.button {
  .fa.fa-eye {
    font-size: 14px;
    margin-right: 10px;
    margin-top: 1px;
  }
}

.center-text {
  text-align: center;
  height: 150px;
  background: #f0f6f6;
  width: 50px;
}

.mini-loader {
  text-align: center;
  height: 60px;
}

// .columns {
//   align-items: flex-start;
// }


.tabs {
  .tab {
    &.inactive {
      opacity: 0.4;
    }
  }

  a {
    text-transform: uppercase;
    font-weight: 900;
    border-bottom: 3px solid;

    &.is-active {
      border-bottom-color: var(--color-primary-10);
      color: var(--color-primary-10);
    }
  }
}

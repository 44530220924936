
.hideMessagePasswordConfirmation {
  color: red;
  display: none;
}
.showMessagePasswordConfirmation {
  color: red;
  display: block;
  text-align: center;
}

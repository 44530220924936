
#security-journals-page {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  .table-wrap {
    display: flex;
    padding: 1em 0;
    width: 70%;
  }
}

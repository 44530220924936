
.dialog-container {
  width: 100%;
  overflow: hidden;

  .form-input-wrap {
    display: flex;
    flex-direction: column;
    overflow: auto;
    // padding: 0 1.3em;

    label {
      margin-top: 0;
    }
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

table.no-border {
  border: 0px;
}

.btn-loader {
  height: 18px;
  padding-right: 4px;
}


.rates-expenses-table {
  .input {
    width: 80px;
    height: 35px;
  }

  .circles {
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: max-content;
    height: 29px;
    width: 29px;
    // background-color: rgb(239, 239, 239);
    transition: all 0.3s ease;
    padding: 2px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    // .circle {
    //   width: 3px;
    //   height: 3px;
    //   border-radius: 50%;
    //   background-color: rgba(0, 0, 0, 0.548);
    //   margin: 2px;
    // }

    &:hover {
      // background-color: silver;

      img {
        filter: brightness(2);
      }
    }
  }

  &.table {
    th {
      position: sticky;
      top: 0;
    }

    .header-row {
      th:not(.row-head):last-child {
        width: 100%;
      }

      .row-head {
        border-right: 1px solid silver;
      }
    }

    td {
      padding: 0.56em 0.65em;
    }

    tbody {
      tr {
        .theading {
          border-right: 1px solid silver;
        }

        td:not(.theading) {
          vertical-align: bottom !important;

          .cell-group {
            display: flex;
            gap: 10px;
            align-items: flex-end;
            // justify-content: flex-end;

            .ms-group {
              width: 200px;
              display: flex;
              flex-direction: column;

              .multiselect {
                width: 100%;
                min-height: 35px;
                height: 35px;
              }
            }
          }
        }
      }
    }
  }

  &.is-pending {
    tbody {
      tr {
        td:not(.theading) {
          background-color: #ffff2557;
        }
      }
    }
  }

  .flex-headers {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .flex-headers-row {
    display: flex;
    align-items: center;
    gap: 10px;

    .group {
      display: flex;
      flex-direction: column;
    }

    .action-col {
      display: flex;
      flex-direction: column;
      gap: 9px;
    }
  }

  // .info {
  //   margin-bottom: -20px;
  // }

  .remove-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;

    .fa {
      // margin-left: 5px;
      // margin-top: 4px;
      font-weight: 600;
      font-size: 1em;
      cursor: pointer;

      &:hover {
        color: rgb(219, 48, 48);
      }
    }
  }
}
